import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { ChangeEvent } from "react";
import * as Yup from "yup";
import { FormikErrors, FormikTouched } from "formik";
import { Typography } from "@material-ui/core";
import { getStorageData } from "../../../framework/src/Utilities";
import ApiRequest from "../../../components/src/ApiRequest.web";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  onClose?: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
name: string;
  email: string;
  phoneNumber: string;
  comments: string;
  enableField: boolean;
  token: string;
  contactUsList: any;
  activeId: number;
  activeName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeDescription: string;
  activeCreatedAt: string;
  isVisible: boolean;
  handleOpenPopup:boolean;
  successPopup:boolean;
  codeBox:boolean;
  searchData:string;
  countryCode:string;
  // Customizable Area End
}

interface TouchedType {
  name: string,
  email: string,
  phonecode: string,
  contactNumber: string,
  subject: string,
  details: string,
}

interface SS {
  id: any;
}

export default class ContactusController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiCallIdContactUsForm: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      name: "",
      email: "",
      phoneNumber: "",
      comments: "",
      enableField: false,
      token: "",
      contactUsList: [],
      activeId: 0,
      activeName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeDescription: "",
      activeCreatedAt: "",
      isVisible: false,
      handleOpenPopup: false,
      successPopup:false,
      codeBox: false,
      searchData: "",
      countryCode:"",
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getUserBasicDetails();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleGetProfileData(from, message);
    const apiRequestCallIds = {
      [this.apiCallIdContactUsForm]: this.submitContactUsResponse,
     
      // Add more API call IDs and handlers as needed
    };
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apierror = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
      apiRequestCallIds[apiRequestCallId](apiResponse, apierror);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getUserDeatilId: string ="";

  handleGetProfileData = (from: string, message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.getUserDeatilId) {
        if (apiResponse) {
          this.getResponseUserProfileData(apiResponse);
          this.setState({handleOpenPopup: true})
          }
    }
  }

  handleOpenPopup = ()=>{
    this.setState({handleOpenPopup: !this.state.handleOpenPopup}, () => {
      if(!this.state.handleOpenPopup && this.props.onClose) {
        this.props.onClose();
      }
    })
  }

  validationSchema = Yup.object({
    name: Yup.string().required('Please enter your name '),
    email: Yup.string().email('Invalid email format').required('Please enter your email ')
    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/,
      'Please enter a valid email'
    ),    contactNumber: Yup.string().required('Please enter your contact number '),
    subject: Yup.string().required('Please enter your subject'),
    details: Yup.string().required('Please enter details '),
  });

  getErrorMessage = (
    touched: FormikTouched<TouchedType>,
    errors: FormikErrors<TouchedType>,
    value: string
  ) => {
    const style = {
      fontSize: "12px",
      color: "#DC2626",
      fontFamily: "DIN Next LT Arabic Regular",
    };
    return (
      <Typography style={style}>
        {touched[value as keyof TouchedType] &&
          errors[value as keyof TouchedType]}
      </Typography>
    );
  };

  handleSubmit = () => {

  }

  handleCodeBox = () => {
    this.setState({codeBox: !this.state.codeBox})
  }

  handleSearchChange = (event: ChangeEvent<HTMLInputElement> | {target: {value: string}}) => {
    this.setState({ searchData: event.target.value });
  };

  handleSuccessPopup = () => {
    this.setState({successPopup: true})
    this.setState({handleOpenPopup: false})
  }

  closeSuccessPopup = () => {
    this.setState({successPopup: false}, () => {
      if(!this.state.handleOpenPopup && this.props.onClose) {
        this.props.onClose();
      }
    })
  }

 getUserBasicDetails=()=>{
  const header = {
    "Content-Type": configJSON.contentTypeApiGetUserProfile,
    "token": localStorage.getItem("token")
  };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserDeatilId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserBasicDetailsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod

    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  submitContactUsRequest = async (values: any) => {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      token
    };

    const payload = {
      "contact": {
        "subject": values.subject,
        "description": values.details,
        "email": values.email,
        "country_code": values.countryCode,
        "phone_number": values.contactNumber
      }
    }

    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.submitContactFormApiEndpoint,
      payload: JSON.stringify(payload),
      method: "POST",
    });

    this.apiCallIdContactUsForm = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getResponseUserProfileData=(response: {data: { attributes: {first_name: string, last_name: string, email: string, country_code: string, phone_number: string}}})=>{
    const userName = response.data.attributes.first_name+" "+response.data.attributes.last_name;
    const email = response.data.attributes.email;
    const countryCode = response.data.attributes.country_code;
    const phoneNumber =response.data.attributes.phone_number;
    this.setState({name:userName, email:email, countryCode:countryCode, phoneNumber:phoneNumber})
  }

  submitContactUsResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson.errors) {
      const errorMessages = responseJson.errors.map((error: any) => {
        return Object.values(error).flat().join(', ');
      }).join('; ');
      toast.error(`Error: ${errorMessages}`);
    } else if (responseJson.data) {
      this.handleSuccessPopup()
    } else {
      toast.error('An unexpected error occurred. Please try again later.');
    }
  };
  
  // Customizable Area End
}
