import React from "react";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import {
  Typography,
  Grid,
  Box,
  Link,
  styled,
  Button,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

// Customizable Area End

import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class RecoveryPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
 
 async componentDidMount(): Promise<void> {
  this.setState({localStorageEmail:await getStorageData("email")})
} 



MainWrapper = styled(Box)(({ theme: any }) => ({
  "& .grid_left": {
    backgroundColor: "#122A6A",
  },
  "& .grid_right": {
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.down("xs")]: {
      backgroundColor: "#122A6A",
    },
  },
  "& .main_box_left": {
    borderRadius: 0,
    padding: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  "& .box_left": {
    flexDirection: "column",
    display: "flex",
    width: "100%",
    height: "100%",
  },
  "& .box_left_column_1": {
    display: "flex",
    height: "20%",
  },
  "& .box_left_column_1_logo": {
    height: "146px",
    margin: "50px 0px 0px 19px",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  "& .box_left_column_2_img": {
    display: "flex",
    height: "50%",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      widht: "22%",
    },
  },
  "& .box_left_column_2_img_tag": {
    padding: "66px",
    width: "50%",
    [theme.breakpoints.between("xs", "lg")]: {
      width: "60%",
      height: "auto",
      padding: "90px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "60px",
      width: "40%",
    },
  },
  "& .box_left_column_3_main_box_text_2_style": {
    fontSize: "17px",
    fontFamily: "DIN Next LT Arabic Regular",
    fontWeight: "500",
    textAlign: "center",
    lineHeight: "28px",
    color: "#DDE0E0",
    [theme.breakpoints.down("xs")]: {
      fontWeight: "500",
      fontFamily: "DIN Next LT Arabic Regular",
      fontSize: "15px",
      lineHeight: "28px",
      textAlign: "center",
      color: "#DDE0E0",
    },
    [theme.breakpoints.down(370)]: {
      fontFamily: "DIN Next LT Arabic Regular",
      lineHeight: "28px",
      fontSize: "12px",
      fontWeight: "500",
      textAlign: "center",
      color: "#DDE0E0",
    },
  },
  "& .box_left_column_3": {
    display: "flex",
    height: "20%",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .box_left_column_3_main_box": {
    height: "160px",
    flexDirection: "column",
    display: "flex",
    width: "423px",
    alignItems: "center",
  },
  "& .box_left_column_3_main_box_text_1": {
    height: "88px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "16px",
      height: "30px",
    },
  },
  "& .box_left_column_3_main_box_text_1_style": {
    fontWeight: "700",
    fontFamily: "DIN Next LT Arabic Bold",
    fontSize: "31px",
    lineHeight: "44px",
    letterSpacing: "-0.01em",
    textAlign: "center",
    color: "#F4F4EA",
    [theme.breakpoints.down(767)]: {
      fontSize: "25px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down(370)]: {
      fontSize: "15px",
    },
  },
  "& .box_left_column_3_main_box_text_2": {
    height: "56px",
    padding: "10px",
    marginTop: "16px",
  },
  
  /* ------------------------------------------*/
 
  "& .main_passwordRecovery_outer_box": {
    margin: "300px 16px",
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down("xs")]: {
      display: 'flex',
      margin: "0px 16px",
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
  
    },
  },
  "& .passwordRecovery_outer_box": {
    marginTop:'40px',
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    gap: "16px",
    [theme.breakpoints.down("xs")]: {
      marginTop:'16px',
      alignSelf: 'stretch' 
    }
  },
  "& .passwordRecovery_style_text_1": {
    fontFamily: 'DIN Next LT Arabic Bold',
    color: 'var(--Neutrals-Cool-gray-900, #0F172A)',
    textAlign: "center",
    fontSize: "30px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "40px",
    letterSpacing: "-0.15px",
    marginTop:'16px',
    [theme.breakpoints.down("xs")]: {
      marginTop:'16px',
      color: 'var(--Primary-Dark-900, #122967)',
      textAlign: 'center',
      fontWeight: 700,
      fontFamily: 'DIN Next LT Arabic Bold',
      fontSize: '24px',
      fontStyle: 'normal',
      lineHeight: '32px',
      letterSpacing: '-0.12px',
    },
  },
  
 
  "& .passwordRecovery_text_style_1": {
    color: 'var(--Neutrals-Cool-gray-900, #0F172A)',
    textAlign: "center",
    fontWeight: 'bold',
    fontFamily: 'DIN Next LT Arabic Regular',
    fontSize: "18px",
    fontStyle: "normal",
    lineHeight: "26px",
    [theme.breakpoints.down("xs")]: {
    color: 'var(--Neutrals-Cool-gray-700, #334155)', 
    textAlign: 'center',
    fontFamily: 'DIN Next LT Arabic Bold',
    lineHeight: '26px', 
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 700,
   },
  },
  "& .passwordRecovery_text_style_2": {
    color:'var(--Neutrals-Cool-gray-700, #334155)',
    fontFamily: 'DIN Next LT Arabic Regular',
    fontSize: "16px",
    textAlign: "center",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
  },
  "& .passwordRecovery_Link_text_style": {
    color: 'var(--Primary-Seconadry-500, #1B4FE4)',
    fontWeight: 700,
    fontFamily: 'DIN Next LT Arabic Bold',
    fontSize: "18px",
    fontStyle: "normal",
    lineHeight: "26px",
  },
  "& .passwordRecovery_Link":{
    marginTop: '40px',
    display: 'inline-flex',
    gap: '10px',
    alignItems: 'center',
    [theme.breakpoints.down("xs")]: {
      display: 'flex',
      gap: '10px', 
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'stretch',
  
    }, 
  
  },
 
  "& .passwordRecovery_button_signin_outer_box": {
    marginTop:'24px',
    display: "flex",
    alignItems: "baseline",
    height: "24px",
    gap: "0px",
    [theme.breakpoints.down("xs")]: {
      marginTop:'16px',
      
    }, 
  },
  "& .passwordRecovery_back_to_style_text": {
    fontSize: "16px",
    color: "var(--Neutrals-Cool-gray-800, #1E293B);",
    fontStyle: 'normal', 
    fontFamily: "DIN Next LT Arabic Regular",
    fontWeight: 400,
    lineHeight: "24px",
  },
  "& .passwordRecovery_button_signin_style_text": {
    color:'var(--Primary-Seconadry-500, #1B4FE4)', 
    fontFamily: "DIN Next LT Arabic Bold",
    fontWeight: 700,
    fontSize: '16px',
    fontStyle: 'normal',
    lineHeight: '24px',
  },
  
  "& .passwordRecovery_button_signin": {
    display: 'flex',
    gap: '8px',
    justifyContent: 'center',
    alignItems: 'flex-start',
    textTransform: "none",
  },
 "& .main_right_box":{
    backgroundColor: "#FFFFFF",
    background: "var(--Basic-White, #FFF)",
    borderRadius: "24px 24px 0px 0px", 
 },
 "& .recoveryPassword_success_box": {
  borderLeft: "4px solid var(--Status-Red-600, #51a775)",
  borderRadius: "4px",
  background: "#C3F3D7",
  display: "flex",
  padding: "12px 16px",
  alignItems: "center",
  gap: "8px",
  marginTop: "40px",
  backgroundColor: "#C3F3D7",
  color: "#51a775",
},
"& .recoveryPassword_success_alert_box": {
  color: "#51a775",
  backgroundColor: "#C3F3D7",
},
"& .recoveryPassword_success_alert_box_style": {
  fontFamily: "DIN Next LT Arabic Regular",
  color:'var(--Status-Red-600, #51a775)',
  fontSize: "14px",
  fontStyle: "normal",
  lineHeight: "22px",
  fontWeight: "400",
  [theme.breakpoints.down(950)]: {
    fontFamily: "DIN Next LT Arabic Regular",
    fontWeight: "400",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "22px",
  },
  [theme.breakpoints.down(650)]: {
    fontStyle: "normal",
    fontFamily: "DIN Next LT Arabic Regular",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "22px",
  },
},
  
}));
renderReSendMessage = (condition:any) => {
  return(
    condition ? (
      <Box className="recoveryPassword_success_box">
        <Box className="recoveryPassword_success_alert_box">
          <Typography className="recoveryPassword_success_alert_box_style">
            {condition}
          </Typography>
        </Box>
      </Box>
    ) : (
      ""
    )
  )
}
 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
      // Required for all blocks
      <this.MainWrapper>
      <ThemeProvider theme={theme}>
        <Grid container spacing={0}>
          <Grid
           className="grid_left"
           item 
           sm={6} 
          xs={12} 
          >
            <Box
             className="main_box_left">
              <Box 
              className="box_left">
                <Box 
                className="box_left_column_1">
                  <Box 
                  className="box_left_column_1_logo">
                    <img
                     src={this.state.imgLogo} 
                     alt="Fundsmen" 
                     />
                  </Box>
                </Box>
                <Box
                 className="box_left_column_2_img">
                  <img
                    src={this.state.imgLeftLogo}
                    alt="Fundsmen"
                    className="box_left_column_2_img_tag"
                  />
                </Box>
                <Box className="box_left_column_3">
                  <Box className="box_left_column_3_main_box">
                    <Box className="box_left_column_3_main_box_text_1">
                      <Typography className="box_left_column_3_main_box_text_1_style"> Invest your money for easy return </Typography>
                    </Box>
                    <Box className="box_left_column_3_main_box_text_2">
                      <Typography className="box_left_column_3_main_box_text_2_style">
                        "Calculate Your Investment Returns in Real Time and
                        Start Growing Your Wealth Today"
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>

         <Grid item xs={12} sm={6} className="grid_right">
         <Box className="main_right_box">
                <Box className="main_passwordRecovery_outer_box">
                  {this.renderReSendMessage(this.state.reSendMessage)}
                  <Typography className="passwordRecovery_style_text_1">
                    Password recovery
                  </Typography>

                  <Box className="passwordRecovery_outer_box">
                    <Typography className="passwordRecovery_text_style_1">
                      Check your email
                    </Typography>

                    <Typography className="passwordRecovery_text_style_2">
                      We have sent a password reset link to {this.state.localStorageEmail}
                    </Typography>
                  </Box>
                  <Link data-test-id="handleRecoveryLinkClick" className="passwordRecovery_Link" style={{cursor:'pointer'}}  onClick={()=>this.handleRecoveryLinkClick({email:this.state.localStorageEmail})}>
                    
                    <Typography className="passwordRecovery_Link_text_style">
                      Re-send email link
                    </Typography>
                    
                  </Link>
                  <Box className="passwordRecovery_button_signin_outer_box">
                    <Typography className="passwordRecovery_back_to_style_text">
                      Back to
                    </Typography>
                    <Button data-test-id="handleSigninClick" onClick={this.handleSigninClick} className="passwordRecovery_button_signin">
                      <Typography className="passwordRecovery_button_signin_style_text">
                        Sign in
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    </this.MainWrapper>
       // Customizable Area End
    );
  }
}
