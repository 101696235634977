export function capitalizeFirstLetters(str: string) {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  export function formatDateToMonDayYear(dateString: string): string {
    const date = new Date(dateString);
    
    // Check if the date is valid
    if (isNaN(date.getTime())) {
      throw new Error('Invalid date string');
    }
  
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
  
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
  
    return `${month} ${day}, ${year}`;
  }

  const isArabicIndicNumeral = (char: string): boolean => {
    return char >= '٠' && char <= '٩';
  };
  
  const isStandardNumeral = (char: string): boolean => {
    return char >= '0' && char <= '9';
  };
  
  const isArabicCharacter = (char: string): boolean => {
    const code = char.charCodeAt(0);
    return (code >= 0x0600 && code <= 0x06FF) || (code >= 0x0750 && code <= 0x077F) || (code >= 0x08A0 && code <= 0x08FF);
  };
  
  const isEnglishCharacter = (char: string): boolean => {
    const code = char.charCodeAt(0);
    return (code >= 65 && code <= 90) || (code >= 97 && code <= 122);
  };
  
  export const isEnglishString = (str: string): boolean => {
    for (let char of str) {
      if (!isEnglishCharacter(char) && char !== ' ') {
        return false;
      }
    }
    return true;
  };

  export const isArabicString = (str: string): boolean => {
    for (let char of str) {
      if (!isArabicCharacter(char) && char !== ' ') {
        return false;
      }
    }
    return true;
  };

  export const isArabicContent = (value: string): boolean => {
    // Regex to match Arabic script including Arabic numerals
    const arabicRegex = /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF\u0660-\u0669\u06F0-\u06F9]/;
    return arabicRegex.test(value);
  };
  
  
  export const isNumericString = (str: string): boolean => {
    for (let char of str) {
      if (!isArabicIndicNumeral(char) && !isStandardNumeral(char) && char !== '.' && char !== ' ' && char !== ',') {
        return false;
      }
    }
    return true;
  };

  export const getCurrentRoute = () => {
    return window.location.pathname;
  };
  
  export function numberToWords(num: number): { arabic: string; english: string } {
    const arabicUnits = ['صفر', 'واحد', 'اثنان', 'ثلاثة', 'أربعة', 'خمسة', 'ستة', 'سبعة', 'ثمانية', 'تسعة'];
    const arabicTens = ['', '', 'عشرون', 'ثلاثون', 'أربعون', 'خمسون', 'ستون', 'سبعون', 'ثمانون', 'تسعون'];
    const arabicTeens = ['عشرة', 'أحد عشر', 'اثنا عشر', 'ثلاثة عشر', 'أربعة عشر', 'خمسة عشر', 'ستة عشر', 'سبعة عشر', 'ثمانية عشر', 'تسعة عشر'];
    const arabicHundreds = ['', 'مائة', 'مئتان', 'ثلاثمائة', 'أربعمائة', 'خمسمائة', 'ستمائة', 'سبعمائة', 'ثمانمائة', 'تسعمائة'];
    const arabicThouSep = ['', 'ألف', 'مليون', 'مليار', 'تريليون', 'كوادريليون', 'كوينتليون'];
  
    const englishUnits = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
    const englishTens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
    const englishTeens = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
    const englishThouSep = ['', 'thousand', 'million', 'billion', 'trillion', 'quadrillion', 'quintillion'];
  
    function convertToArabic(n: number): string {
      if (n === 0) return arabicUnits[0];
      if (n < 0) return `سالب ${convertToArabic(-n)}`;
      
      let result = '';
      let sepIndex = 0;
      
      while (n > 0) {
        if (n % 1000 !== 0) {
          let chunk = convertArabicChunk(n % 1000);
          if (sepIndex > 0) {
            chunk += ` ${arabicThouSep[sepIndex]}`;
          }
          result = chunk + (result ? ` و${result}` : '');
        }
        n = Math.floor(n / 1000);
        sepIndex++;
      }
      
      return result;
    }
  
    function convertArabicChunk(n: number): string {
      if (n === 0) return '';
      if (n < 10) return arabicUnits[n];
      if (n < 20) return arabicTeens[n - 10];
      if (n < 100) {
        const unit = n % 10;
        const ten = Math.floor(n / 10);
        return unit > 0 ? `${arabicUnits[unit]} و${arabicTens[ten]}` : arabicTens[ten];
      }
      const hundred = Math.floor(n / 100);
      const remainder = n % 100;
      return remainder > 0 ? `${arabicHundreds[hundred]} و${convertArabicChunk(remainder)}` : arabicHundreds[hundred];
    }
  
    function convertToEnglish(n: number): string {
      if (n === 0) return englishUnits[0];
      if (n < 0) return `negative ${convertToEnglish(-n)}`;
      
      let result = '';
      let sepIndex = 0;
      
      while (n > 0) {
        if (n % 1000 !== 0) {
          let chunk = convertEnglishChunk(n % 1000);
          if (sepIndex > 0) {
            chunk += ` ${englishThouSep[sepIndex]}`;
          }
          result = chunk + (result ? ` ${result}` : '');
        }
        n = Math.floor(n / 1000);
        sepIndex++;
      }
      
      return result;
    }
  
    function convertEnglishChunk(n: number): string {
      if (n === 0) return '';
      if (n < 10) return englishUnits[n];
      if (n < 20) return englishTeens[n - 10];
      if (n < 100) {
        const unit = n % 10;
        const ten = Math.floor(n / 10);
        return unit > 0 ? `${englishTens[ten]}-${englishUnits[unit]}` : englishTens[ten];
      }
      const hundred = Math.floor(n / 100);
      const remainder = n % 100;
      return remainder > 0 ? `${englishUnits[hundred]} hundred and ${convertEnglishChunk(remainder)}` : `${englishUnits[hundred]} hundred`;
    }
  
    return {
      arabic: `${convertToArabic(num)} ريال عماني`,
      english: `${convertToEnglish(num)} Omani Rial`
    };
  }
  

  export const applyBlueColor = (text: string): string => {
    return `<span style="color: blue;">${text}</span>`;
  };
  