import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

interface ISignupSuccessModalProps {
    open: boolean;
    handleClose: () => void;
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    subContainer: {
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: "40px 30px 40px 30px",
        textAlign: 'center',
        width: '55%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    icon: {
        color: theme.palette.success.main,
        fontSize: '3rem',
        marginBottom: '13px',
    },
    title: {
       color: '#020214',
       textAlign: 'center',
       fontFamily: 'DIN Next LT Arabic Bold',
       fontSize: '24.8px',
       fontStyle: 'normal',
       lineHeight: '33.067px',
       letterSpacing: '-0.124px',
       marginBottom: '8px'
    },
    description: {
        color: '#020214',
        textAlign: 'center',
        fontFamily: 'DIN Next LT Arabic Light',
        fontSize: '13.227px',
        fontStyle: 'normal',
        lineHeight: '19.84px',
    },
    closeButton: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
    },
}));

const SignupSuccessModal = ({ open, handleClose }: ISignupSuccessModalProps) => {
    const classes = useStyles();

    if (!open) return null;

    return (
        <div className={classes.container} onClick={handleClose}>
            <div className={classes.subContainer}>
                <div className={classes.paper} onClick={(e) => e.stopPropagation()}>
                    <CheckCircleIcon className={classes.icon} />
                    <Typography id="transition-modal-title" className={classes.title}>
                        Account has been successfully created
                    </Typography>
                    <Typography id="transition-modal-description" className={classes.description}>
                        Your account has been successfully created and is now pending admin review
                    </Typography>
                </div>
            </div>
        </div>
    );
};

export default SignupSuccessModal;
