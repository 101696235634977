import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  i18n?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeAnswerIndex: any;
  landingPageData: any;
  activeHappyInvestors: any;
  freqAskQuestion: any;
  activeLink: any;
  activeProtfolios: any;
  openIntCalculator:boolean;
  newsLetterEmail:string;
  currentLanguage: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  landingPageApiCalled: any;
  newsLetterId:any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      activeAnswerIndex: "",
      openIntCalculator:false,
      landingPageData: {},
      freqAskQuestion: [],
      activeHappyInvestors: {
        first: 0,
        second: 1,
        third: 2,
      },
      activeProtfolios: {
        first: 0,
        second: 1,
        third: 2
      },
      activeLink: "home",
      newsLetterEmail:"",
      currentLanguage: this.props.i18n?.language,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handlePostNewsLetter(from ,message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.handleCondition(
      apiRequestCallId === this.landingPageApiCalled,
     this.handle(responseJson),
      ""
    );
    // Customizable Area End
  }

  // Customizable Area Start

  handle = (responseJson:any) => {
    if(!responseJson.errors) {
      this.setState({
        landingPageData: responseJson.data.attributes,
        freqAskQuestion:
          responseJson.data.attributes.frequently_asked_questions.data,
      })
    }
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getLandingPageDetails();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if(prevState.currentLanguage !== this.state.currentLanguage) {
      this.getLandingPageDetails()
    }
  }
  handleClick = (index: any) => {
    this.setState({
      activeHappyInvestors: {
        first: this.handleCondition(
          index - 1 < 0,
          this.state.landingPageData.happy_investors.data.length - 1,
          index - 1
        ),
        second: index,
        third: this.handleCondition(
          index + 1 >
            this.state.landingPageData.happy_investors.data.length - 1,
          0,
          index + 1
        ),
      },
    });
  };
  handleIndex = (index: any) => {
    this.setState({
      activeProtfolios: {
        first: this.handleCondition(
          index - 1 < 0,
          this.state.landingPageData.portfolio_images.data.length - 1,
          index - 1
        ),
        second: index,
        third: this.handleCondition(
          index + 1 >
            this.state.landingPageData.portfolio_images.data.length - 1,
          0,
          index + 1
        ),
      },
    });
  };
  handlePortfolioClick = (type:string) => {
    const {first, second, third} = this.state.activeProtfolios;
    const totalLength = this.state.landingPageData.portfolio_images.data.length;
    if(type === 'next') {
      this.setState({activeProtfolios: {
        first: first === totalLength ? 0 : (first + 1) % totalLength,
        second: second === totalLength ? 0 : (second + 1) % totalLength,
        third: third === totalLength ? 0 : (third + 1) % totalLength
      }})
    }
    if(type === 'prev') {
      this.setState({activeProtfolios: {
        first: (first - 1 + totalLength) % totalLength,
        second: (second - 1 + totalLength) % totalLength,
        third: (third - 1 + totalLength) % totalLength
      }})
    }
  }
  handleRenderJsx = (index: string, type: string) => {
    return this.handleCondition(
      type !== "image",
      this.state.landingPageData.happy_investors?.data[
        this.state.activeHappyInvestors[index]
      ]?.attributes[type],
      this.state.landingPageData.happy_investors?.data[
        this.state.activeHappyInvestors[index]
      ]?.attributes[type]?.url
    );
  };
  handleNavigate = (path: string) => {
    this.props.navigation.navigate(path);
  };
  handleActiveLink = (link: any) => {
    this.setState({ activeLink: link });
  };
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  setActiveAnswerIndex(index: any) {
    this.setState({
      activeAnswerIndex: this.state.activeAnswerIndex === index ? "" : index,
    });
  }
  handleCondition = (condition: any, truePart: any, falsePart: any) => {
    return condition ? truePart : falsePart;
  };
  
  openInteractiveCalculator = () => {
    this.setState({openIntCalculator: !this.state.openIntCalculator});
  }

  displayState = () => {
    this.setState({openIntCalculator: !this.state.openIntCalculator});
  }
  
  getLandingPageDetails = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.landingPageApiCalled = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.landingpageGetApiEndpint}?language=${this.state.currentLanguage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handlePostNewsLetter = (from: string, message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.newsLetterId) {
      if(!apiResponse.errors) {
        toast.success(apiResponse.message)
        this.setState({newsLetterEmail:""})
      }else{
        toast.error(apiResponse.errors[0].email)
      }
    }
  }

  handleNewsLetterApi =() => {
    const formResponseData = new FormData();
    formResponseData.append("email", this.state.newsLetterEmail);
  const header = {
    contentType: configJSON.validationApiContentType,
    token: localStorage.getItem("token"),
  };
  const newsData = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  newsData.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  this.newsLetterId = newsData.messageId;

  newsData.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.newsLetterEndPoint
  );
  newsData.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formResponseData
  );
  newsData.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.exampleAPiMethod
  );
  runEngine.sendMessage(newsData.id, newsData);
};

handleLanguageChange = (lng: string) => {
  const { i18n } = this.props;
  i18n.changeLanguage(lng);
  this.setState({currentLanguage: lng})
}

handleNavigateToTC = () => {
  const goToTermsAndCondition = new Message(getName(MessageEnum.NavigationMessage));
  goToTermsAndCondition.addData(getName(MessageEnum.NavigationTargetMessage), "TermsConditions");
  goToTermsAndCondition.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(goToTermsAndCondition);
}
  // Customizable Area End
}
