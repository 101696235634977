Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.getContractContentApiEndPoint = "/bx_block_custom_form/contract_forms";
exports.postContractPdfApiEndPoint = "bx_block_custom_form/signed_contracts";
exports.getApiMethod = 'GET';
exports.postApiMethod = 'POST';
// Customizable Area End