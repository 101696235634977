import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Select, MenuItem, FormControl } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { flagSaudi, flagUK } from '../../blocks/user-profile-basic/src/assets';
import { useTranslation } from 'react-i18next';




interface LanguageOption {
    value: string;
    label: string;
    flag: string;
}

interface LanguageSwitchProps {
    isLandingPage: boolean;
    lang: string;
    handleLanguageChange: (lang: string) => void;
}



const LanguageSwitch: React.FC<LanguageSwitchProps> = ({ isLandingPage = false, lang, handleLanguageChange }) => {
    const conditionalTxtColor = isLandingPage ? 'black' : 'white';
    const conditionalBorder = isLandingPage ? 'rgb(27, 79, 228)' : 'white';
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            formControl: {
                
            },
            select: {
                border: `0.01vw solid ${conditionalBorder}`,
                borderRadius: '0.4vw',
                color: conditionalTxtColor,
                '&:before': {
                    borderColor: conditionalTxtColor,
                },
                '&:after': {
                    borderColor: conditionalTxtColor,
                },
                '& .MuiSvgIcon-root': {
                    color: conditionalBorder,
                    width:"2vw",
                },'& .MuiInputBase-input':{
                    padding:"0",
                    [theme.breakpoints.down(608)]: {
                        padding: "0vw 1.8vw",
                     },
                },
                '& .MuiSelect-selectMenu': {
                    [theme.breakpoints.down(966)]: {
                        padding: "0.1vw 1.8vw",
                    height:"2.2vh"
                     },
                     [theme.breakpoints.down(650)]: {
                        padding: "0vw 1.8vw",
                     },
                    [theme.breakpoints.down(480)]: {
                        padding: "0.5vw 2vw",
                        fontSize: "2.5vw",
                        borderRadius: "1.2vw",
                        fontWeight: 500,
                        height:"fit-content"
                    },
                    color: conditionalBorder,
                    padding: "0.4vw 1.8vw",
                    fontSize: "1vw",
                    borderRadius: "1.2vw",
                    fontWeight: 700,
                    minHeight: 'unset',
                    display: 'flex',
                    paddingLeft: '3px',
                    alignItems: 'center',
                    justifyContent:"center",
                    height:"fit-content"
                }
            },
            icon: {
                fill: conditionalTxtColor,
                width:"2vw",
            },
            menuItem: {
                display: 'flex',
                alignItems: 'center',
                gap:'2px'
            },
            flag: {
                width: "1.5vw",
                [theme.breakpoints.down(480)]: {
                   width: "4vw"
                },
            },
            menu: {
                backgroundColor: 'white',
            },
        })
    );
    const classes = useStyles();
    const { t } = useTranslation();
    const [language, setLanguage] = useState<string>(lang);

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const lang = event.target.value as string;
        setLanguage(lang);
        handleLanguageChange(lang)
    };

    const languageOptions: LanguageOption[] = [
        { value: 'en', label: `${t('english')}`, flag: flagUK },
        { value: 'ar', label: `${t('arabic')}`, flag: flagSaudi },
    ];

    useEffect(() => {
        setLanguage(lang)
    }, [lang])

    return (
        <FormControl className={classes.formControl}>
            <Select
                value={language}
                onChange={handleChange}
                displayEmpty
                className={classes.select}
                IconComponent={KeyboardArrowDownIcon}
                disableUnderline
                MenuProps={{
                    classes: { paper: classes.menu },
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                }}
            >
                {languageOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value} className={classes.menuItem}>
                        <img src={option.flag} alt={`${option.value} flag`} className={classes.flag} />
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default LanguageSwitch;
