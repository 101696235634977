import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData} from "../../../framework/src/Utilities";
import { imgArrow, imgArrowSmall, crossIconButton, imgUploadBoxLarge, imgUploadBoxSmall, imgAddress, imgIcon, imgAddressSmall, imgIconSmall } from "./assets";
import React from "react";
import * as Yup from 'yup';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { isNumericString, isEnglishString, isArabicString, isArabicContent } from "../../../components/src/utils.web"
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: string;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
export interface MyFormProps {}
export interface MyFormState {
  initialValues: { [key: string]: string };
  validationSchema: Yup.ObjectSchema<any>;
  htmlParts: string[];
  placeholders: string[];
  arabicHtmlParts: string[];
  arabicPlaceholders: string[];
}

export interface FormErrors {
  contractAmount?: string;
  second_party_english?: string;
  civilian_number?: string;
}

export interface Touched {
  
}

export interface Error {
  
}



export interface ContractContentData {
  data: {
    id: number;
    created_at: string;
    attributes:{
      description: string;
      arabic_content:string;
      bank_detail:{
        data:{
            attributes: {
              bank_name: string;
              account_number: string;
              swift_code: string;
              account_holder_name:string,
            }
        }
      },
      account_detail:{
        data:{
          attributes:{
            first_name :string;
            last_name :string;
          }
        }
      }
    }
  
};
}
// Customizable Area End

interface S {
  // Customizable Area Start
  loading: boolean;
  contractContent:string;
  arabicContractContent:string;
  bankDetails:{
    bank_name: string;
    account_number: string;
    swift_code: string;
    account_holder_name:string,
  },
  userDetails:{
    first_name:string,
    last_name:string
  },
  imgArrow:string;
  imgArrowSmall:string;
  crossIconButton:string;
  imgUploadBoxLarge:string;
  imgUploadBoxSmall:string;
  open:boolean;
  imagePreviewUrl:string | null;
  inputRef: any;
  checkedBtn:boolean;
  errorBox : boolean;
  initialValues: { [key: string]: string };
  initialValuesArabic: { [key: string]: string };
  validationSchema: Yup.ObjectSchema<any>;
  validationSchemaArabic: Yup.ObjectSchema<any>;
  htmlParts: string[];
  placeholders: string[];
  arabicHtmlParts: string[];
  arabicPlaceholders: string[];
  contentArabic:string[],
  contentEnglish:string[],
  errorBoxSign:boolean;
  imgAddress:string;
  imgIcon:string;
  imgAddressSmall:string;
  imgIconSmall:string;
  width:number;
  contractAmount: string;
  second_party_english: string;
  second_party_arabic: string;
  civilian_number: string;
  errors: FormErrors;
  isArabic: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class CustomFormController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getContractContentApiCallId: string = "";
  postContractPdfApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      contractContent:'',
      bankDetails:{
        bank_name: '',
        account_number: '',
        swift_code: '',
        account_holder_name:'',
      },
      userDetails:{ 
        first_name:'',
        last_name:''
      },
      arabicContractContent:'',
      imgArrow:imgArrow,
      imgArrowSmall:imgArrowSmall,
      crossIconButton:crossIconButton,
      imgUploadBoxLarge:imgUploadBoxLarge,
      imgUploadBoxSmall:imgUploadBoxSmall,
      open:false,
      imagePreviewUrl:null,
      inputRef: React.createRef(),
      checkedBtn:false,
      errorBox:false,
      initialValues: {},
      initialValuesArabic: {},
      validationSchema: Yup.object().shape({}),
      validationSchemaArabic: Yup.object().shape({}),
      htmlParts: [],
      placeholders: [],
      arabicHtmlParts: [],
      arabicPlaceholders: [],
      contentArabic:[],
      contentEnglish:[],
      errorBoxSign:false,
      imgAddress:imgAddress,
      imgIcon:imgIcon,
      imgAddressSmall:imgAddressSmall,
      imgIconSmall:imgIconSmall,
      width: window.innerWidth,
      contractAmount: '',
      second_party_english: '',
      second_party_arabic: '',
      civilian_number: '',
      errors: {},
      isArabic: false,
      // Customizable Area End
    };

    // Customizable Area Start  
    this.handleResize = this.handleResize.bind(this);

 

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.errors) {
          const goTologin = new Message(getName(MessageEnum.NavigationMessage));
          goTologin.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
          goTologin.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
          this.send(goTologin);
          
        } else if (apiRequestCallId == this.getContractContentApiCallId) {
          this.getContractContentResponse(message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)));
        } else if (apiRequestCallId == this.postContractPdfApiCallId) {
          
          this.getContractPdfPostResponse(message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)));
          this.setState({loading:false})
         
        } 
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getContractContent();
    window.addEventListener('resize', this.handleResize);

    // Load data from localStorage when component mounts
    const savedContractAmount = await getStorageData('contractAmount');
    const savedSecondaryPartyEnglish = await getStorageData('second_party_english');
    const savedSecondaryPartyArabic = await getStorageData('second_party_arabic');
    const savedCivilianNumber = await getStorageData('civilian_number');

    if (savedContractAmount) this.setState({contractAmount: savedContractAmount});
    if (savedSecondaryPartyEnglish) this.setState({second_party_english: savedSecondaryPartyEnglish});
    if (savedSecondaryPartyArabic) this.setState({second_party_arabic: savedSecondaryPartyArabic});
    if (savedCivilianNumber) this.setState({civilian_number: savedCivilianNumber});
  }
  

  async componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.setState({ width: window.innerWidth });
  }
  getContractContent = async () => {
    let token= await getStorageData("token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getContractContentApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContractContentApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  postContractPdf = async (values:any) => {
    const {second_party_english, second_party_arabic, contractAmount, civilian_number} = this.state;
    let token= await getStorageData("token");
    let pdf =await values;
    const header = {
      token,
    };
    const requestId = await getStorageData("depositRequestId");
    const formData = new FormData();
    formData.append("signed_contract[request_id]", requestId);
    (typeof pdf!="string")  && formData.append('signed_contract[contract_file]', pdf,'form.pdf');
    formData.append("signed_contract[second_party_name]", second_party_english)
    formData.append("signed_contract[second_party_name_ar]", second_party_arabic)
    formData.append("signed_contract[contract_amount]", contractAmount)
    formData.append("signed_contract[civilian_number]", civilian_number)

     
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postContractPdfApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postContractPdfApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getContractContentResponse=(responseJson:ContractContentData)=>{
    this.setState({contractContent:responseJson.data.attributes.description});
    this.setState({arabicContractContent:responseJson.data.attributes.arabic_content});
    this.setState({bankDetails : responseJson.data.attributes.bank_detail.data.attributes});
    this.setState({userDetails : responseJson.data.attributes.account_detail.data.attributes});
    const contentEnglish = responseJson?.data.attributes.description.split(/<\/?p>/).filter(Boolean);
    const contentArabic = responseJson?.data.attributes.arabic_content.split(/<\/?p>/).filter(Boolean);
    this.setState({contentEnglish : contentEnglish})
    this.setState({contentArabic : contentArabic})
    const { htmlParts, placeholders } = this.extractPlaceholdersAndParts(responseJson?.data.attributes.description)
    this.setState({htmlParts:htmlParts,placeholders:placeholders})
    const initialValues = this.createInitialValues(placeholders);
    this.setState({initialValues:initialValues})
    const validationSchema = this.createValidationSchema(placeholders);
    this.setState({validationSchema:validationSchema})

  }
  getContractPdfPostResponse= async (responseJson?:any)=>{
    if(responseJson.meta.message==="Your contract has been submitted successfully"){
      this.setState({open:true})
      await setStorageData("contractCompleted", true);

      // remove storage data
      await removeStorageData('contractAmount');
      await removeStorageData('second_party_english');
      await removeStorageData('second_party_arabic');
      await removeStorageData('civilian_number');
    }
  }
  navigateToDashBoardPage = async ()=>{
    const goToDashboard = new Message(getName(MessageEnum.NavigationMessage));
    goToDashboard.addData(getName(MessageEnum.NavigationTargetMessage), "DepositRequest");
    goToDashboard.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(goToDashboard);
    // remove storage data
    await removeStorageData('contractAmount');
    await removeStorageData('second_party_english');
    await removeStorageData('second_party_arabic');
    await removeStorageData('civilian_number');

  }
  handleClose = () => {
    this.setState({ open: false });
  };
  handleClickCloseUploadBox = () =>{
    this.setState({open:false});
  }
 
  goToTermsAndConds=()=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'TermsConditions'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { successMessage : "CustomForm" })
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);

  }
  imgUploadBoxOpen = () => {
    return this.state.inputRef.current
      ? this.state.inputRef.current.click()
      : "";
  };
  setCheckBoxValue = (value: boolean) =>{
    this.setState({ checkedBtn: value });

  }


  extractPlaceholdersAndParts(html: string): { htmlParts: string[], placeholders: string[] } {
    const regex = /\[([A-Z\s]+)\]/g;
    const htmlParts: string[] = [];
    const placeholders: string[] = [];
    let lastIndex = 0;
    let match;
    while ((match = regex.exec(html)) !== null) {
      htmlParts.push(html.slice(lastIndex, match.index));
      placeholders.push(match[1].trim());
      lastIndex = regex.lastIndex;
    }
    htmlParts.push(html.slice(lastIndex));
    return { htmlParts, placeholders };
  }

  createInitialValues(placeholders: string[]): { [key: string]: string } {
    const initialValues: { [key: string]: string } = {};
    placeholders.forEach(placeholder => {
      initialValues[placeholder] = '';
    });
    return initialValues;
  }

 
  createValidationSchema(placeholders: string[]): Yup.ObjectSchema<any> {
    const shape: { [key: string]: Yup.Schema<any> } = {};

    placeholders.forEach(placeholder => {
      shape[placeholder] = Yup.string().required(`${placeholder} is required`);
    });

    shape['checkbox'] = Yup.boolean()
      
      .test('checkbox', 'Checkbox must be checked', (value) => {
        if (!value) {
          this.setState({ errorBox: true });
          return false;
        }
        this.setState({ errorBox: false });
        return true;
      });

    return Yup.object().shape(shape);
  }

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, field: keyof S) => {
    const value = e.target.value;
    let isValid: boolean;
    let isArabic: boolean;
    const maxAmount = 99999999999999999999;
  
    if (value === '') {
      isValid = true;
    } else {
      const isNotJustWhitespace = value.trim() !== '';
  
      switch (field) {
        case 'contractAmount':
          isValid = isNotJustWhitespace && isNumericString(value);
          if (isValid) {
            const numericValue = Number(value);
            isValid = numericValue <= maxAmount;
          }
          break;
        case 'second_party_english':
          isValid = isNotJustWhitespace && isEnglishString(value);
          isArabic = isArabicContent(value)
          break;
        case 'second_party_arabic':
          isValid = isNotJustWhitespace && isArabicString(value);
          isArabic = isArabicContent(value)
          break;
        case 'civilian_number':
          isValid = isNotJustWhitespace && isNumericString(value);
          break;
        default:
          isValid = isNotJustWhitespace;
      }
    }
  
    if (isValid) {
      this.setState((prevState: S) => ({
        ...prevState,
        [field]: value,
        isArabic
      }));

      // Save to localStorage whenever input changes
      setStorageData(field, value)
    }
  }



  generatePDF = async () => {
    const element = document.getElementById('pdf-content');
    if (!element) return;
  
    const padding = 30;
    const fixedWidth = 800; 
    const fixedHeight = 8200;
    
    element.style.padding = `${padding}px`;
  
    element.style.transform = 'none';
  
    const canvas = await html2canvas(element, {
      scale: 1.5,
      logging: false,
      windowWidth: element.offsetWidth * 1.5,
      windowHeight: element.offsetHeight * 1.5,
      useCORS: true
    });
  
    const pdf = new jsPDF('p', 'px', [fixedWidth + (padding * 2), fixedHeight + (padding * 2)], true);
  
    const imgData = canvas.toDataURL('image/png');
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  
    canvas.width = pdfWidth;
    canvas.height = pdfHeight;
  
    pdf.addImage(imgData, 'PNG', padding, padding, pdfWidth - (padding * 2), pdfHeight - (padding * 2));
  
    const pdfBlob = pdf.output('blob');
  
    return pdfBlob;
  };

  validateForm = (): boolean => {
    const { contractAmount, second_party_english, second_party_arabic, civilian_number} = this.state;
    const newErrors: FormErrors = {};

    // Check if fields are empty
    if (!contractAmount.trim()) {
      newErrors.contractAmount = 'Contract amount is required';
    } 
    if (!second_party_english.trim()) {
      newErrors.second_party_english = 'Second party (English) is required';
    } 
    if (!second_party_arabic.trim()) {
      newErrors.second_party_english = 'Second party (Arabic) is required';
    } 

    if (!civilian_number.trim()) {
      newErrors.civilian_number = 'Civilian number is required';
    }


   this.setState({errors: newErrors})
    return Object.keys(newErrors).length === 0;
  };

  submitForm = (values:any) => {
    if(this.validateForm()){
      if(values.file){
        this.setState({errorBoxSign:false})
        this.setState({loading:true})
        let button = document.getElementById('button');
        button!.style.display = 'none'
        let checkbox = document.getElementById('checkbox');
        checkbox!.style.display = 'none'
          // Replace input fields with their values
          const inputFields = document.querySelectorAll('#pdf-content input');
          inputFields.forEach((input: any) => {
            const span = document.createElement('span');
            span.textContent = input.value + ' ';
            span.style.fontFamily = 'DIN Next LT Arabic Bold';
            span.style.color = 'blue';
            input.parentNode!.replaceChild(span, input);
          });
        this.postContractPdf(this.generatePDF())
        }else{
          this.setState({errorBoxSign:true})
        }
    }
  
  }

  handleClearError = () => {
    this.setState({
      errorBox: false,
    })
  }


  // Customizable Area End
}
