import React from "react";
// Customizable Area Start
import {
  Box,
  styled,
  createTheme,
  Typography,
  Button,
  Dialog,
  DialogContent,
  Popover,
} from "@material-ui/core";
import {Area, AreaChart, Tooltip} from "recharts";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
import {
  PlusIcon,
  bellIcon,
  searchIcon,
  arrow,
  drop,
  fourline,
  activity,
  calendar,
  plusButton,
  searchButton,
  threeDots,
  message,
  downloadIcon,
  optionIcon,
  rejected,
  pending,
  markAsRead,
  deleteIcon
} from "./assets";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  MainWrapper = styled(Box)({
    zIndex:-1,
    position:"absolute",
    width: "calc(100vw - 358px)",
    [theme.breakpoints.up(900)]:{
      zIndex:0,
      position:"inherit",
    },
    "& .container": {
      [theme.breakpoints.down(900)]: {
        position: "absolute",
        top: "0",
        padding: "0",
        zIndex: 99,
        width: "calc(100vw - 0px)",
        height:"calc(100vh - 1px)",
      },
      width: "calc(100vw - 358px)",
      height:"calc(100vh - 4.5rem)",
      padding: "4rem 10px 10px 0rem",
      overflow:"scroll",
    },
    "& .linkBox": {
      [theme.breakpoints.down(900)]: {},
    },
    "& .dashboardBox": {
      [theme.breakpoints.down(900)]: {
        width: "calc(100vw - 75px)",
      },
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      textAlign: "center",
      padding: "0 20px 0 40px",
      width: "calc(100% - 60px)",
      minWidth: "calc(100% - 60px)",
    },
    "& .dashboardStyle": {
      textAlign: "center",
      color: "#334155",
      fontSize: "30px",
      fontFamily: "DIN Next LT Arabic Bold",
    },
    "& .dashboardBox2": {
      [theme.breakpoints.down(900)]: {
          width: "100%"
        },
      [theme.breakpoints.down(380)]: {
        gap: "10px",
      },
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      gap: "20px",
      width: "fit-content",
    },
    "& .dashboardSearch": {
      fontSize: "16px",
      border: "none",
      color: "#94A3B8",
      fontFamily: "DIN Next LT Arabic Regular",
      width: "100%"
    },
    "& .requestButton": {
      [theme.breakpoints.down(900)]: {
        padding: "14px 8px",
      },
      [theme.breakpoints.down(750)]: {
        display: "none",
      },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#FFFFFF",
      backgroundColor: "#1B4FE4",
      gap: "8px",
      padding: "12px 8px",
      borderRadius: "8px",
      flexWrap: "nowrap",
    },
    "& .buttonOne": {
      [theme.breakpoints.down(1451)]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down(1370)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(1262)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(900)]: {
        fontSize: "12px",
      },
      color: "#FFFFFF",
      fontWeight: 700,
      backgroundColor: "#1B4FE4",
      fontSize: "16px",
      border: "none",
      fontFamily: "DIN Next LT Arabic Bold",
      flexWrap: "nowrap",
    },
    "& .serachBox": {
      [theme.breakpoints.down(900)]: {
        width: "calc(30% - 16px)",
      },
      [theme.breakpoints.down(750)]: {
        display: "none",
      },
      border: "1px solid #64748B",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      padding: "10px 8px",
      borderRadius: "8px",
      gap: "8px",
      width: "calc(30% - 16px)",
    },
    "& .serchStyle": {
      width: "24px",
      height: "32px",
    },
    "& .plusStyle": {
      [theme.breakpoints.down(1276)]: { width: "23px", height: "23px" },
      [theme.breakpoints.down(1217)]: { width: "20px", height: "20px" },
      [theme.breakpoints.down(1120)]: { width: "17px", height: "17px" },
      [theme.breakpoints.down(1021)]: { width: "15px", height: "15px" },
      [theme.breakpoints.down(900)]: {},
    },
    "& .totalReviewBox": {
      [theme.breakpoints.down(900)]: {
        width: "calc(100vw - 75px)",
      },
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "48px 40px",
      height: "fit-content",
      gap: "24px",
      width: "calc(100% - 80px)",
      minWidth: "calc(100% - 80px)",
    },
    "& .reviewBox": {
      [theme.breakpoints.down(900)]: {
        width: "170",
        height: "130px",
        gap: "8px",
        padding: "4px 8px",
      },
      
      [theme.breakpoints.down(750)]: {
        display: "none",
      },
      width: "calc(25% - 48px)",
      height: "150px",
      padding: "16px 24px",
      gap: "16px",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      flexDirection: "column",
    },
    "& .totalInvest": {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .totalText": {
      fontSize: "16px",
      fontWeight: 400,
      fontFamily: "DIN Next LT Arabic Light",
    },
    "& .totalPercent": {
      padding: "2px 8px",
      borderRadius: "40px",
      color: "#FFFFFF",
      backgroundColor: "#EF4444",
      fontSize: "12px",
      fontWeight: 500,
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontFamily: "DIN Next LT Arabic Regular",
    },
    "& .totalNumber": {
      [theme.breakpoints.down(900)]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down(750)]: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        fontSize: "18px",
        width: "100%",
      },
      fontSize: "18px",
      width: "100%",
      fontWeight: 700,
      fontFamily: "DIN Next LT Arabic Bold",
    },
    "& .reportsContainer": {
      [theme.breakpoints.down(900)]: {
      width: "calc(100vw - 75px)",
      },
      [theme.breakpoints.up(750)]: {
        borderTop: "1px solid  #CBD5E1",
        borderBottom: "1px solid #CBD5E1",
      },
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "calc(100% - 80px)",
      padding: "48px 40px",
      gap: "48px",
      flexDirection:"column",
    },
    "& .reportsBox": {
      [theme.breakpoints.down(480)]: {},
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: "8px",
      width: "calc(60%- 20px)",
    },
    "& .report": {
      [theme.breakpoints.down(480)]: {},
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
    },
    "& .expensesText": {
      [theme.breakpoints.down(480)]: {},
      fontSize: "16px",
      color: "#1B4FE4",
      fontWeight: 700,
      fontFamily: "DIN Next LT Arabic Bold",
      cursor: "pointer",
    },
    "& .expensesButton": {
      color: "#475569",
      fontWeight: 400,
      fontFamily: "DIN Next LT Arabic Light",
      fontSize: "14px",
      borderRadius: "26px",
      border: " 1px solid #94A3B8",
      padding: "8px 12px 6px 10px",
      width: "calc(100% - 22px)",
    },
    "& .activityTable": {
      [theme.breakpoints.down(900)]: {
        width: "calc(100vw - 20px)",
      },
      [theme.breakpoints.down(750)]: {
        flexDirection: "column",
      },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "calc(100% - 30px)",
      minWidth: "calc(100% - 30px)",
      height: "fit-content",
      gap: "24px",
      padding: "48px 10px",
      borderBottom: "1px solid #CBD5E1",
    },
    "& .leftActivity": {
      [theme.breakpoints.down(750)]: {
        width: "100%",
      },
      display: "flex",
      justifyContent: "space-between",
      gap: "24px",
      alignItems: "center",
      width: "calc(100% - 100px)",
      padding: "16px 40px",
      flexDirection: "column",
    },
    "& .activityText": {
      [theme.breakpoints.down(750)]: {
        fontSize: "16px",
      },
      width: "100%",
      fontSize: "18px",
      color: " #1E293B",
      fontWeight: 700,
      fontFamily: "DIN Next LT Arabic Bold",
    },
    "   & .activityrightttext": {
      [theme.breakpoints.down(750)]: {
        display: "none",
      },
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      fontSize: "18px",
      color: " #1B4FE4",
      fontWeight: 700,
      fontFamily: "DIN Next LT Arabic Bold",
      gap: "8px",
    },
    "& .rightActivity": {
      [theme.breakpoints.down(480)]: {},
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    "& .waweStyle": {
      [theme.breakpoints.down(900)]: {
        width: "10rem",
      },
      [theme.breakpoints.down(831)]: {
        width: "9rem",
      },
      width: "100%",
    },
    "& .activityBox": {
      [theme.breakpoints.down(480)]: {},
      width: "100%",
      gap: "8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .calendar": {
      [theme.breakpoints.down(480)]: {},
      fontWeight: 700,
      fontSize: "16px",
      color: "#1B4FE4",
      fontFamily: "DIN Next LT Arabic Bold",
      width: "80px",
    },
    "& .reportss": {
      [theme.breakpoints.down(480)]: {},
      fontWeight: 700,
      fontSize: "18px",
      color: "#1E293B",
      fontFamily: "DIN Next LT Arabic Bold",
    },
    "& .headingBox": {
      width: "100%",
      gap: "24px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .headingStyle": {
      [theme.breakpoints.down(750)]: {
        fontSize: "16px",
      },
      fontSize: "14px",
      width: "100%",
      textAlign: "center",
      fontWeight: 700,
      fontFamily: "DIN Next LT Arabic Bold",
    },
    "& .itemBox": {
      width: "100%",
      gap: "24px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .itemData": {
      width: "100%",
      fontSize: "14px",
      fontWeight: 400,
      textAlign: "center",
      fontFamily: "DIN Next LT Arabic Regular",
    },
    "& .itemDataBal": {
      [theme.breakpoints.down(480)]: {},
      width: "100%",
      fontSize: "12px",
      fontWeight: 500,
      textAlign: "center",
      borderRadius: "40px",
      color: "#fff",
      fontFamily: "DIN Next LT Arabic Regular",
    },
    "& .bellIcon": {
      [theme.breakpoints.down(900)]: {
        width: "1rem",
      },
      [theme.breakpoints.down(750)]: {
        width: "24px",
        height: "24px",
      },
    },
    "& .Expenses": {
      [theme.breakpoints.down(900)]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "48px",
      },
      [theme.breakpoints.down(750)]: {
        gap: "18px",
      },
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      gap: "48px",
      width: "fit-content",
    },
    "& .expenseDrop": {
      [theme.breakpoints.down(900)]: {},
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "5px",
      width: "100%",
    },
    "& .dashBox": {
      [theme.breakpoints.down(900)]: {},
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      paddingBottom: "10px",
    },
    "& .ButtonPlus": {
      [theme.breakpoints.down(750)]: {
        width: "40px",
        height: "40px",
      },
      [theme.breakpoints.up(750)]: {
        display: "none",
      },
    },
    "& .mobileSearch": {
      [theme.breakpoints.down(750)]: {
        width: "40px",
        height: "40px",
      },
      [theme.breakpoints.up(750)]: {
        display: "none",
      },
    },
    "& .circlePager": {
      [theme.breakpoints.up(750)]: {
        display: "none",
      },
      padding: "20px 10px",
      width: "100vw",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "12px",
    },
    "& .circle": {
      [theme.breakpoints.down(750)]: {
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        backgroundColor: "#E2E8F0",
      },
    },
    "& .circle.active": {
      backgroundColor: "#1B4FE4",
    },
    "& .threeDots": {
      [theme.breakpoints.down(750)]: {
        width: "35px",
        height: "35px",
      },
      [theme.breakpoints.up(750)]: {
        display: "none",
      },
    },
    "& .statusBox": {
      [theme.breakpoints.down(750)]: {
        display: "none",
      },
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "24px",
      width: "100%",
      textAlign: "center",
    },
    "& .calenderIcon": {
      [theme.breakpoints.down(750)]: {
        width: "24px",
        height: "24px",
      },
      [theme.breakpoints.up(750)]: {
        display: "none",
      },
    },
    "& .acountIcon": {
      [theme.breakpoints.down(750)]: {
        width: "24px",
        height: "24px",
      },
    },
    "& .mobileReviewBox": {
      [theme.breakpoints.down(750)]: {},
      [theme.breakpoints.up(750)]: {
        display: "none",
      },
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      flexDirection: "column",
      gap: "16px",
      padding: "0 20px 0 0",
    },
    "& .mobileTextBox": {
      [theme.breakpoints.down(750)]: {},
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
    "& .mobileTextBoxNum": {
      [theme.breakpoints.down(750)]: {},
      backgroundColor: "#EF4444",
      borderRadius: "40px",
      textAlign: "center",
      width: "48px",
      height: "22px",
      padding: "2px 8px",
    },
    "& .mobileNum": {
      [theme.breakpoints.down(750)]: {},
      color: "#FFFFFF",
      fontSize: "14px",
      fontFamily: "DIN Next LT Arabic Regular",
    },
    "& .mobileAmount": {
      [theme.breakpoints.down(750)]: {},
      display: "flex",
      width: "100%",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    "& .mobileImage": {
      [theme.breakpoints.down(750)]: {},
      display: "flex",
      width: "100%",
      height: "23px",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    "& .profitContainer": {
      [theme.breakpoints.down(900)]: {
        top: 300,
        right: 0,
      },
      [theme.breakpoints.down(555)]: {
        top: 350,
        right: 0,
      },
      position: "fixed" as "fixed",
      top: 400,
      right: -450,
      height: "100%",
      zIndex: 30,
      display: "flex",
      justifyContent: "center" as "center",
      alignItems: "flex-start",
      width: "100%",
    },
    "& .popupBox": {
      [theme.breakpoints.down(900)]: {
        width: "30%",
      },
      [theme.breakpoints.down(555)]: {
        width: "50%",
      },
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column" as "column",
      justifyContent: "flex-start",
      alignItems: "center",
      backgroundColor: "#FFFFFF",
      top: 0,
      boxShadow: "0px 2px 4px 0px #00000026",
      width: "12%",
    },
    "& .profitData": {
      fontSize: "16px",
      fontFamily: "DIN Next LT Arabic Light",
      padding: "12px 16px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      color: "#3C3E49",
    },
    "& .active": {
      backgroundColor: "#1B4FE4",
      color: "#F8FAFC",
    },
  });

  CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div style={{height:"fit-content",backgroundColor:"#1B4FE4",
        top:0,paddingTop:"0px", padding:"16px 24px", borderRadius:"12px",
         display:"flex",alignItems:"center",justifyContent:"center",
          flexDirection:"column", gap:"1px"}}>
          <p style={{fontSize:"24px", color:"#FFFFFF",fontFamily: "DIN Next LT Arabic Regular"}}>{payload[0].payload.amt}</p>
          <div>
          <div style={{ display: "inline-block", padding: 10 }}>
            <div style={{display:"flex", gap:"5px"}}>
                <div style={{color:"#FFFFFF", fontSize:"18px"}}>{payload[0].payload.date}</div>
                <div style={{color:"#FFFFFF", fontSize:"18px"}}>{payload[0].payload.time}</div>
                </div>
              </div>
          </div>
        </div>
      );
    }
  
    return null;
  };

  data = [
    { name: 'Jan', uv: 4000, pv: 6548600, amt: 2400, date: "01 Apr 2024", time: "04:44 PM" },
    { name: 'Feb', uv: 3000, pv: 65486.00, amt: 2210, date: "01 Apr 2024", time: "04:44 PM" },
    { name: 'Mar', uv: 2000, pv: 6548600, amt: 2290, date: "01 Apr 2024", time: "04:44 PM" },
    { name: 'Apr', uv: 2780, pv: 6548600, amt: 2000, date: "01 Apr 2024", time: "04:44 PM" },
    { name: 'May', uv: 1890, pv: 6548600, amt: 2181, date: "01 Apr 2024", time: "04:44 PM" },
    { name: 'Jun', uv: 2390, pv: 6548600, amt: 2500, date: "01 Apr 2024", time: "04:44 PM" },
    { name: 'Jul', uv: 3490, pv: 6548600, amt: 2100, date: "01 Apr 2024", time: "04:44 PM" },
    { name: 'Jan', uv: 4000, pv: 6548600, amt: 2400, date: "01 Apr 2024", time: "04:44 PM" },
    { name: 'Feb', uv: 3000, pv: 6548600, amt: 2210, date: "01 Apr 2024", time: "04:44 PM" },
    { name: 'Mar', uv: 2000, pv: 6548600, amt: 2290, date: "01 Apr 2024", time: "04:44 PM" },
    { name: 'Apr', uv: 2780, pv: 6548600, amt: 2000, date: "01 Apr 2024", time: "04:44 PM" },
    { name: 'May', uv: 1890, pv: 4800, amt: 2181, date: "01 Apr 2024", time: "04:44 PM" },
    { name: 'Jun', uv: 2390, pv: 3800, amt: 2500, date: "01 Apr 2024", time: "04:44 PM" },
    { name: 'Jul', uv: 3490, pv: 4300, amt: 2100, date: "01 Apr 2024", time: "04:44 PM" },
  ];

  // Customizable Area End

  render() {
    // Customizable Area Start
    const getStatusIcon = (status: string | null) => {
      if (status === "pending") {
        return pending;
      } else if (status === "rejected") {
        return rejected;
      } else {
        return message;
      }
    }

    return (
      <div style={{ display: "flex", width: "fit-content"}}>
        <Box style={webStyle.navigationMenu}>
          <NavigationMenu id="" navigation={this.props.navigation}></NavigationMenu>
        </Box>
        <this.MainWrapper>
          <Box className="container">
            <Dialog
              open={this.state.isDialogOpen}
              onClose={this.handleDialogClose}
              aria-labelledby="dialog-title"
              aria-describedby="dialog-description"
              PaperProps={{
                style: {
                  marginLeft: "52rem",
                  borderRadius: 0,
                },
              }}
            >
              <DialogContent style={{ padding: 0 }}>
                <Box style={webStyle.notificationTab} >
                  <Box style={webStyle.notificationHeader} >
                    <Typography style={webStyle.headerText} >Notification</Typography>
                    <Typography style={webStyle.headerTextMarkAsRead} data-test-id="handleMarkAllAsRead" onClick={() => this.handleMarkAllAsRead()} >Mark all as Read</Typography>
                  </Box>
                  {
                    this.state.data.slice(0, 5).map((item, itemIndex) => (
                      <Box style={{
                        ...webStyle.notificationContainer,
                        backgroundColor: item.attributes.is_read ? "#FFFFFF" : "#EFF6FF",
                      }} key={itemIndex} >
                        <img src={getStatusIcon(item.attributes.status)} alt="message icon" style={{ height: "24px", width: "24px" }} />
                        <Box style={webStyle.notificationContent}>
                          <Typography  >{item.attributes.contents}</Typography>
                          <Typography style={webStyle.timerContent} >{item.attributes.created_at}</Typography>
                        </Box>
                        <Typography data-test-id="downloadBtn" style={webStyle.downloadBtn} onClick={() => this.downloadFile(`${item.attributes.app_url}`)}>
                          <img src={downloadIcon} alt="download-icon" />
                          Download
                        </Typography>
                        <img data-test-id="moreBtn" src={optionIcon} alt="options icon" onClick={(event) => this.handleOptionOpen(event, item.id)} />
                        <Popover
                          id="simple-popover"
                          anchorEl={this.state.anchorEl}
                          open={Boolean(this.state.anchorEl)}
                          onClose={this.handleOptionClose}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          PaperProps={{
                            style: webStyle.optionMenu,
                          }}
                        >
                          <Box style={webStyle.optionMarkAsRead}>
                            <img src={markAsRead} alt="markAsReadIcon" style={{ height: "24px", width: "24px" }} />
                            <Typography data-test-id="markAsRead" onClick={() => this.handleMarkAsRead()} style={{ cursor: "pointer" }} >Mark as read</Typography>
                          </Box>
                          <Box style={webStyle.optionDelete}>
                            <img src={deleteIcon} alt="deleteIcon" style={{ height: "24px", width: "24px" }} />
                            <Typography data-test-id="delete" style={{ cursor: "pointer" }} onClick={() => this.handleDeleteNotification()} >Delete</Typography>
                          </Box>
                        </Popover>
                      </Box>
                    )
                    )}
                  <Box style={webStyle.allNotificationContainer} >
                    <Box>
                      <Typography data-test-id="handleNavigation" onClick={() => this.handleNavigation('Notifications')} style={webStyle.allNotifications} >See All Notifications</Typography>
                    </Box>
                  </Box>
                </Box>
              </DialogContent>
            </Dialog>
            {this.state.totalProfitPopup && (
              <Box className="profitContainer">
                <Box className="popupBox">
                  {this.state.profitList.map((item, index) => (
                    <Button
                      data-test-id={`profitList${index}`}
                      key={index}
                      className={`profitData ${this.state.activeTab === index
                          ? "active"
                          : "profitData"
                        }`}
                      onClick={() => {
                        this.setState({ activeTab: index });
                      }}
                    >
                      {item.label}
                    </Button>
                  ))}
                </Box>
              </Box>
            )}
            <Box className="dashboardBox">
              <Box className="dashBox">
                <Typography className="dashboardStyle">Dashboard</Typography>
              </Box>
              <Box className="dashboardBox2">
                <Box className="serachBox">
                  <img src={searchIcon} 
                  className="serchStyle" 
                  />
                  <input
                    placeholder="Type to search"
                    className="dashboardSearch"
                  />
                </Box>
                <img data-test-id="bellBtn" src={bellIcon} onClick={this.handleDialogOpen} className="bellIcon" />
                <img src={searchButton} className="mobileSearch" />
                <img src={plusButton} className="ButtonPlus" />
                <Box className="requestButton">
                  <img src={PlusIcon} className="plusStyle" />
                  <button className="buttonOne">New Withdraw Request</button>
                </Box>
                <Box className="requestButton">
                  <img src={PlusIcon} className="plusStyle" />
                  <button className="buttonOne">New Deposit Request</button>
                </Box>
              </Box>
            </Box>
            <Box className="totalReviewBox">
                {this.state.investmentData.map(
                  (
                    item: {
                      name: string;
                      color: string;
                      percentage: string;
                      total: string;
                      image: string;
                    },
                    index: number
                  ) => (
                    <Box className="reviewBox" key={index}>
                      <Box className="totalInvest">
                        <Typography className="totalText">
                          {item.name}
                        </Typography>
                        <Box
                          style={{ backgroundColor: item.color }}
                          className="totalPercent"
                        >
                          {item.percentage}
                        </Box>
                      </Box>
                      <Typography className="totalNumber">
                        {item.total}
                      </Typography>
                      <img
                        src={item.image}
                        alt="img.png"
                        className="waweStyle"
                      />
                    </Box>
                  )
                )}
                <Box className="mobileReviewBox">
                  <Box className="mobileTextBox">
                    <Typography className="totalText">
                      {
                        this.state.investmentData[this.state.activeData.first]
                          .name
                      }
                    </Typography>
                    <Box className="mobileTextBoxNum">
                      <Typography className="totalPercent">
                        {
                          this.state.investmentData[this.state.activeData.first]
                            .percentage
                        }
                      </Typography>
                    </Box>
                  </Box>
                  <Typography className="totalNumber">
                    {
                      this.state.investmentData[this.state.activeData.first]
                        .total
                    }
                  </Typography>
                  <img
                    src={
                      this.state.investmentData[this.state.activeData.first]
                        .image
                    }
                    className="waweStyle"
                  />
                </Box>
              </Box>
            <Box className="circlePager">
                {this.state.investmentData.map((item, index) => (
                  <Box
                    key={index}
                    data-test-id = {`investmentData${index}`}
                    className={`circle ${
                      this.state.activeCircle === index ? "active" : ""
                    }`}
                    onClick={() => this.setActiveCircle(index)}
                  ></Box>
                ))}
              </Box>
              <Box className="reportsContainer">
              <Box style={{display:"flex", alignItems:"flex-start", justifyContent:"space-between", width:"100%"}}>
                <Box className="reportsBox">
                    <img src={arrow} />
                    <Typography className="reportss">Reports</Typography>
                </Box>
                <Box className="Expenses">
                  <Box className="expenseDrop" onClick={this.handleClick}>
                    <Typography className="expensesText">Total Profit</Typography>
                    <img src={drop} alt="" />
                  </Box>
                  <img src={fourline} className="fourline" />
                  <img src={threeDots} className="threeDots" />
                  <Box className="statusBox">
                    <Typography className="expensesButton">Monthly</Typography>
                    <Typography className="expensesButton">Weekly</Typography>
                  </Box>
                </Box>
                </Box>
                <AreaChart
                  className="chartDashboard"
                  width={this.state.graphWidth}
                  height={400}
                  data={this.data}
                  margin={{top: 10, right: 0, left: 0, bottom: 0}}
                  >
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                          <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                        </linearGradient>
                    </defs>
                  <Tooltip content={<this.CustomTooltip />} />
                  <Area type="monotone" dataKey="uv" strokeWidth={2} stroke="#1B4FE4" fill="url(#colorUv)" />
                </AreaChart>
            </Box>
            <Box className="activityTable">
                <Box className="leftActivity">
                  <Box className="activityBox">
                    <img src={activity} alt="/" className="acountIcon" />
                    <Typography className="activityText">
                      Account Activity
                    </Typography>
                    <img
                      src={calendar}
                      alt=""
                      className="calenderIcon"
                      onClick={this.handleCalendarOpen}
                    />
                    <Box className="activityrightttext">
                      <Typography className="calendar">Calendar</Typography>
                      <img
                        src={calendar}
                        alt=""
                        onClick={this.handleCalendarOpen}
                      />
                    </Box>
                  </Box>
                  <Box className="headingBox">
                    <Typography className="headingStyle">Date</Typography>
                    <Typography className="headingStyle">
                      Description
                    </Typography>
                    <Typography className="headingStyle">Amount</Typography>
                    <Typography className="headingStyle">Balance</Typography>
                  </Box>
                  {this.state.transactionsActivity.map(
                    (
                      item: {
                        date: string;
                        type: string;
                        amountColor: string;
                        amount: string;
                        balance: string;
                      },
                      index: number
                    ) => (
                      <Box className="itemBox" key={index}>
                        <Typography className="itemData">
                          {item.date}
                        </Typography>
                        <Typography className="itemData">
                          {item.type}
                        </Typography>
                        <Typography
                          className="itemData"
                          style={{ color: item.amountColor }}
                        >
                          {item.amount}
                        </Typography>
                        <Typography
                          className="itemDataBal"
                          style={{ backgroundColor: item.amountColor }}
                        >
                          {item.balance}
                        </Typography>
                      </Box>
                    )
                  )}
                </Box>
              </Box>
          </Box>
        </this.MainWrapper>
      </div>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  navigationMenu: {
    zIndex: 100,
    height: "fit-content",
    width: "fit-content",
  },
  notificationTab:{
    width: "492px",
  },
  notificationHeader:{
    display: "flex",
    justifyContent : "space-between",
    borderBottom: "1px solid #DDE0E0",
    padding :"16px 24px 16px 24px"
  },

  headerText:{
    fontWeight :"400",
    fontSize : "16px"
  },

  headerTextMarkAsRead:{
    fontWeight :"400",
    fontSize : "16px",
    color: "#1B4FE4",
    cursor:"pointer"
  },

  notificationContainer:{
   height : "120px",
    backgroundColor: "#EFF6FF",
    display: "flex",
    alignItems: "center",
    padding: "18px 40px 18px 40px",
    gap: "24px",
    borderBottom: "1px solid #CBD5E1",
  },

  notificationContent:{
    flex:"1",
    width : "185px",
    height:"auto",
    fontSize:"14px",
    fontWeight:"400",
    color:"#0F172A",
  },
  timerContent:{
    fontWeight:"400",
    fontSize:"12px",
    color:"#64748B"
  },
  downloadBtn: {
    fontWeight: "400",
    fontSize: "16px",
    color: "#1B4FE4",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    cursor:"pointer"
  },
  allNotificationContainer:{
    height:"56px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
  },
  allNotifications:{
    color:"#1B4FE4",
    fontSize:"16px",
    fontWeight:"400",
    cursor :"pointer"
  },
  optionMenu:{
    height:"96px",
    width: "178px",
    border: "1px solid #F5F5F5",
    boxShadow: "none",
    borderRadius:"8px",
    background: "#FFFFFF"
  },
  optionMarkAsRead:{
    display:"flex",
    height:"48px",
    alignItems:"center",
    gap:"8px",
    paddingLeft:"16px",
    fontWeight:"400",
    fontSize:"16px",
    color:"#F8FAFC",
    backgroundColor:"#1B4FE4"
  },
  optionDelete:{
    display:"flex",
    height:"48px",
    alignItems:"center",
    gap:"8px",
    paddingLeft:"16px",
    fontWeight:"400",
    fontSize:"16px"
  }
};
// Customizable Area End
