import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Formik } from "formik";
import Visibility from "@material-ui/icons/Visibility";
import * as Yup from "yup";
import { getStorageData } from "../../../framework/src/Utilities";
import {
  Button,
  Box,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  styled,
  TextField,
} from "@material-ui/core";
// Customizable Area End

import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class NewPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
 userSchema = () => {
  return Yup.object().shape({
    password: Yup.string().required("Please enter your password"),
    cpassword: Yup.string().required("Please confirm your password"),
  });
};

async componentDidMount(): Promise<void> {
    this.setState({localStorageEmail:await getStorageData("email")})
} 


getErrorMessage = (touched: any, errors: any, value: any) => {
  return this.handleCondition(
    this.handleCondition(touched[value], errors[value], ""),
    <Typography
      style={{ 
        fontFamily: 'DIN Next LT Arabic Regular',
        fontSize: '12px',
        fontStyle: 'normal',
        color: 'var(--Status-Red-600, #DC2626)',
        fontWeight: 400,
        lineHeight: '18px',
       }}
    >
      {errors[value]}
    </Typography>,
    ""
  );
};




MainWrapper = styled(Box)(({ theme: any }) => ({
  "& .main_right_box": {
    backgroundColor: "#FFFFFF",
    background: "var(--Basic-White, #FFF)",
    borderRadius: "24px 24px 0px 0px",
  },
  "& .main_newPassword_outer_box": {
      display: 'flex',
      flexDirection: 'column',
      alignitems: 'center',
      margin: "241px 90px",
      
    [theme.breakpoints.down(920)]: {
      margin: "241px 65px",
    },
    [theme.breakpoints.down(820)]: {
      margin: "241px 55px",
    },
    [theme.breakpoints.down(750)]: {
      margin: "241px 45px",
    },
    [theme.breakpoints.down(670)]: {
      margin: "241px 35px",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0px 16px",
    },
  },
  "& .newPassword_style_text_1": {
    color: "var(--Neutrals-Cool-gray-900, #0F172A)",
    fontFamily: "DIN Next LT Arabic Bold",
    textAlign: "center",
    fontSize: "30px",
    lineHeight: "40px",
    fontStyle: 'normal',
    fontWeight: '700',
    letterSpacing: "-0.15px",
    [theme.breakpoints.down("xs")]: {
      color: "var(--Primary-Dark-900, #122967)",
      paddingTop: "16px",
      fontFamily: "DIN Next LT Arabic Bold",
      textAlign: "center",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "32px",
      letterSpacing: "-0.12px",
    },
  },
  "& .newPassword_style_text_2": {
    color: "var(--Neutrals-Cool-gray-800, #1E293B)",
    textAlign: "center",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "DIN Next LT Arabic Regular",
    fontStyle: "normal",
    lineHeight: "24px",
    marginTop: "10px",
    [theme.breakpoints.down("xs")]: {
      color: 'var(--Neutrals-Cool-gray-700, #334155)'
    },
  },
  "& .newPassword_npassword_text_style": {
    color: "var(--Neutrals-Cool-gray-700, #334155)",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "22px",
    fontFamily: "DIN Next LT Arabic Bold",
    fontWeight: 700,
    marginTop: "40px",
    [theme.breakpoints.down('xs')]:{
      marginTop:'16px'
    }
  },
  "& .newPassword_cpassword_text_style": {
    fontSize: "14px",
    color: "var(--Neutrals-Cool-gray-700, #334155)",
    fontFamily: "DIN Next LT Arabic Bold",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "22px",
    marginTop: "20px",
    [theme.breakpoints.down('xs')]:{
      marginTop:'16px'
    }
  },
  "& .newPassword_outer_box": {
    flexDirection: "column",
    alignItems: "center",
    display: "flex",
    gap: "16px",
    marginTop: "32px",
    [theme.breakpoints.down('xs')]:{
      marginTop:'16px'
    }
  },
  "& .newPassword_button": {
    width: "100%",
    display: "flex",
    height: "44px",
    justifyContent: "center",
    padding: "10px 16px",
    alignItems: "center",
    gap: "8px",
    borderRadius: "8px",
    textTransform: "none",
    background: "#1B4FE4",
  },
  "& .newPassword_button_style": {
    fontFamily: "DIN Next LT Arabic Bold",
    color: 'var(--Basic-White, #FFF)',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: "24px",
    fontWeight: 700,
   
  },
  "& .newPassword_button_signup_outer_box": {
    alignItems: "baseline",
    display: "flex",
    height: "24px",
    gap: "0px",
    marginTop: '-8px'
  },
  "& .newPassword_back_to_style_text": {
    color: "var(--Neutrals-Cool-gray-800, #1E293B)",
    fontFamily: "DIN Next LT Arabic Regular",
    lineHeight: "24px",
    fontSize: "16px",
    fontStyle: 'normal',
    fontWeight: 400,
  },
  "& .newPassword_button_signup": {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "8px",
    textTransform: "none",
  },
  "& .newPassword_button_signup_style_text": {
    color: "var(--Primary-Seconadry-500, #1B4FE4)",
    fontWeight: 700,
    fontSize: "16px",
    fontFamily: "DIN Next LT Arabic Bold",
    lineHeight: "24px",
    fontStyle: 'normal',
  },
  "& .newPassword_validation_check_box": {
    marginTop: "20px",
    flexDirection: "column",
    alignItems: "flex-start",
    display: "flex",
    gap: "8px",
    [theme.breakpoints.down('xs')]:{
      marginTop:'16px'
    } 
  },

  "& .newPassword_validation_check_title_style": {
    fontFamily: "DIN Next LT Arabic Regular",
    color: "var(--Neutrals-Cool-gray-700, #334155)", 
    fontSize: "16px",
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "24px", 
  },
  "& .newPassword_validation_check_inner_box": {
    display: "flex",
    alignItems: "flex-start",
    gap: "8px",
    flexDirection: "column",
  },
  "& .newPassword_validation_check_span": {
    display: "flex",
    gap: "6px",
    alignItems: "center",
  },
  "& .newPassword_validation_check_text_style": {
    color: "var(--Neutrals-Cool-gray-800, #1E293B)",
    fontSize: "12px",
    fontFamily: "DIN Next LT Arabic Regular",
    fontWeight: 400,
    lineHeight: "18px",
    fontStyle: "normal",
    [theme.breakpoints.down(935)]: {
      color: "var(--Neutrals-Cool-gray-800, #1E293B)",
    fontFamily: "DIN Next LT Arabic Regular",
    fontSize: "9.5px",
    lineHeight: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    },
    [theme.breakpoints.down('xs')]: {
      fontFamily: "DIN Next LT Arabic Regular",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      color: "var(--Neutrals-Cool-gray-800, #1E293B)",
      lineHeight: "18px",
    },

  },
  "& .newPassword_error_box": {
    borderLeft: "4px solid var(--Status-Red-600, #DC2626)",
    borderRadius: "4px",
    background: "var(--Status-Red-100, #FEE2E2)",
    display: "flex",
    padding: "12px 16px",
    alignItems: "center",
    gap: "8px",
    marginTop: "40px",
    backgroundColor: "#FEE2E2",
    color: "#DC2626",
  },
  "& .newPassword_error_alert_box": {
    color: "#DC2626",
    backgroundColor: "#FEE2E2",
  },
  "& .newPassword_error_alert_box_style": {
    fontFamily: "DIN Next LT Arabic Regular",
    color:'var(--Status-Red-600, #DC2626)',
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "22px",
    fontWeight: "400",
    [theme.breakpoints.down(950)]: {
      fontFamily: "DIN Next LT Arabic Regular",
      fontWeight: "400",
      fontSize: "12px",
      fontStyle: "normal",
      lineHeight: "22px",
    },
    [theme.breakpoints.down(650)]: {
      fontStyle: "normal",
      fontFamily: "DIN Next LT Arabic Regular",
      fontSize: "10px",
      fontWeight: "400",
      lineHeight: "22px",
    },
  },
  "& .MuiOutlinedInput-input":{
      alignSelf: 'baseline',
      padding: '0px 0px !important',
      
  },
  /*----------------------------------------*/
  "& .grid_left": {
    backgroundColor: "#122A6A",
  },
  "& .grid_right": {
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.down("xs")]: {
      backgroundColor: "#122A6A",
    },
  },
  "& .main_box_left": {
    borderRadius: 0,
    padding: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  "& .box_left": {
    flexDirection: "column",
    display: "flex",
    width: "100%",
    height: "100%",
  },
  "& .box_left_column_1": {
    height: "20%",
    display: "flex",
  },
  "& .box_left_column_1_logo": {
    margin: "50px 0px 0px 19px",
    height: "146px",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  "& .box_left_column_2_img": {
    height: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      widht: "22%",
    },
  },
  "& .box_left_column_2_img_tag": {
    padding: "66px",
    width: "50%",
    [theme.breakpoints.between("xs", "lg")]: {
      padding: "45px",
      width: "60%",
      height: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "60px",
      width: "40%",
    },
  },
  "& .box_left_column_3": {
    height: "20%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .box_left_column_3_main_box": {
    display: "flex",
    height: "160px",
    alignItems: "center",
    flexDirection: "column",
    width: "423px",
  },
  "& .box_left_column_3_main_box_text_1": {
    height: "88px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "16px",
      height: "30px",
    },
  },
  "& .box_left_column_3_main_box_text_1_style": {
    fontFamily: "DIN Next LT Arabic Bold",
    fontSize: "31px",
    fontWeight: "700",
    letterSpacing: "-0.01em",
    lineHeight: "44px",
    color: "#F4F4EA",
    textAlign: "center",
    [theme.breakpoints.down(767)]: {
      fontSize: "25px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down(370)]: {
      fontSize: "15px",
    },
  },
  "& .box_left_column_3_main_box_text_2": {
    height: "56px",
    padding: "10px",
    marginTop: "16px",
  },
  "& .box_left_column_3_main_box_text_2_style": {
    fontFamily: "DIN Next LT Arabic Regular",
    fontSize: "17px",
    fontWeight: "500",
    textAlign: "center",
    lineHeight: "28px",
    color: "#DDE0E0",
    [theme.breakpoints.down("xs")]: {
      fontWeight: "500",
      fontFamily: "DIN Next LT Arabic Regular",
      fontSize: "15px",
      lineHeight: "28px",
      textAlign: "center",
      color: "#DDE0E0",
    },
    [theme.breakpoints.down(370)]: {
      fontFamily: "DIN Next LT Arabic Regular",
      fontWeight: "500",
      lineHeight: "28px",
      fontSize: "12px",
      textAlign: "center",
      color: "#DDE0E0",
    },
  },
   /* ------------------------------------------*/

}));
renderPasswordValidation = (validationCondition:any)=>{return(
  validationCondition ? (
    <Box className="newPassword_error_box">
      <Box className="newPassword_error_alert_box">
        <Typography className="newPassword_error_alert_box_style">
          {validationCondition}
        </Typography>
      </Box>
    </Box>
  ) : (
    ""
  )

)}
renderPasswordEye = (conditionValidation:any)=>{return(
  conditionValidation ? (
    <VisibilityOff />
  ) : (
    <Visibility />
  )
)}
renderConfirmPasswordEye = (conditionValidation:any)=>{return(
  conditionValidation ? (
    <VisibilityOff />
  ) : (
    <Visibility />
  )
)}


 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
      // Required for all blocks
      <this.MainWrapper>
        <ThemeProvider theme={theme}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={6} 
            className="grid_left">
              <Box className="main_box_left">
                <Box className="box_left">
                  <Box className="box_left_column_1">
                    <Box className="box_left_column_1_logo"><img alt="Fundsmen" src={this.state.imgLogo} /></Box>
                  </Box>
                  <Box className="box_left_column_2_img"><img
                      className="box_left_column_2_img_tag"
                      alt="Fundsmen"
                      src={this.state.imgLeftLogo}/>
                  </Box>
                  <Box className="box_left_column_3">
                    <Box className="box_left_column_3_main_box">
                      <Box className="box_left_column_3_main_box_text_1">
                        <Typography className="box_left_column_3_main_box_text_1_style">
                          Invest your money for easy return</Typography>
                      </Box>
                      <Box className="box_left_column_3_main_box_text_2">
                        <Typography className="box_left_column_3_main_box_text_2_style">
                          "Calculate Your Investment Returns in Real Time and
                          Start Growing Your Wealth Today"</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} className="grid_right">
              <Box className="main_right_box">
                <Box className="main_newPassword_outer_box">
                  <Typography className="newPassword_style_text_1">New Password
                  </Typography>
                  <Typography className="newPassword_style_text_2">
                    Enter a new password for {this.state.localStorageEmail}
                  </Typography>
                  {this.renderPasswordValidation(this.state.PasswordValidation)}
                  <Formik
                    data-test-id="formik"
                    initialValues={{
                      password: this.state.password,
                      cpassword:this.state.cpassword
                    }}
                    validationSchema={this.userSchema}
                    onSubmit={(values: any) => {
                      this.handleSetNewPasswordClick(values);
                      
                      
                    }}
                  >
                    {({
                      handleSubmit,
                      errors,
                      values,
                      handleBlur,
                      touched,
                      setFieldValue,
                    }) => (
                      <form
                        onSubmit={handleSubmit}
                        noValidate
                        autoComplete="off"
                      >
                        <Typography className="newPassword_npassword_text_style">
                          New Password
                        </Typography>

                        <TextField
                          data-test-id="txtInputNewPassword"
                          className="MuiOutlinedInput-input"
                          variant="outlined"
                          type={
                            this.state.enablePasswordField ? "password" : "text"
                          }
                          name="password"
                          placeholder={"Your new password"}
                          fullWidth
                          onBlur={handleBlur}
                          value={values.password}
                          onChange={(e) => {
                            setFieldValue("password", e.target.value);
                            this.handlePasswordCheckValidation(e.target.value);
                          }}
                          error={!!errors.password && !!touched.password}
                          style={{
                            width: "100%",
                          }}
                          InputProps={{
                            style: {
                              width: "100%",
                              background: theme.palette.common.white,
                              position: "relative",
                              height: "44px",
                              display: "flex",
                              padding: "10px 8px",
                              flexShrink: 0,
                              borderRadius: "8px",
                              marginTop: "6px",
                              fontSize: "16px",
                              fontStyle: "normal",
                              alignItems: "center",
                              fontFamily: "DIN Next LT Arabic Regular",
                              fontWeight: 400,
                              lineHeight: "24px",
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  data-test-id="handleClickShowPassword"
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowPassword}
                                  edge="end"
                                  style={{padding: '6px', margin: '-6px'}}
                                >
                                  {this.renderPasswordEye(this.state.enablePasswordField)}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                         <Box style={{marginTop:'5px'}}>   {this.getErrorMessage(touched, errors, "password")}</Box>

                        <Typography className="newPassword_cpassword_text_style">
                        Confirm new password
                        </Typography>

                        <TextField
                          data-test-id="txtInputConfirmPassword"
                          variant="outlined"
                          name="cpassword"
                          placeholder={"Confirm your new password"}
                          fullWidth
                          onBlur={handleBlur}
                          value={values.cpassword}
                          type={
                            this.state.enableCPasswordField ? "password" : "text"
                          }
                          onChange={(e) =>
                            setFieldValue("cpassword", e.target.value)
                          }
                          error={!!errors.cpassword && !!touched.cpassword}
                          style={{
                            width: "100%",
                          }}
                          InputProps={{
                            style: {
                              fontStyle: "normal",
                              width: "100%",
                              height: "44px",
                              position: "relative",
                              display: "flex",
                              alignItems: "center",
                              padding: "10px 8px",
                              flexShrink: 0,
                              borderRadius: "8px",
                              marginTop: "6px",
                              background: theme.palette.common.white,
                              fontSize: "16px",
                              fontWeight: 400,
                              fontFamily: "DIN Next LT Arabic Regular",
                              lineHeight: "24px",
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowCPassword}
                                  edge="end"
                                  style={{padding: '6px', margin: '-6px'}}
                                >
                                 {this.renderConfirmPasswordEye(this.state.enableCPasswordField)}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                         <Box style={{marginTop:'5px'}}>   {this.getErrorMessage(touched, errors, "cpassword")}</Box>
                        <Box className="newPassword_validation_check_box">
                          <Typography className="newPassword_validation_check_title_style">
                            Your password must contain
                          </Typography>
                          <Box className="newPassword_validation_check_inner_box">
                            <Box>
                              <span className="newPassword_validation_check_span">
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M6.66683 11.3333L3.3335 7.99992L4.2735 7.05325L6.66683 9.44659L11.7268 4.38659L12.6668 5.33325M8.00016 1.33325C7.12468 1.33325 6.25778 1.50569 5.44894 1.84072C4.6401 2.17575 3.90517 2.66682 3.28612 3.28587C2.03588 4.53612 1.3335 6.23181 1.3335 7.99992C1.3335 9.76803 2.03588 11.4637 3.28612 12.714C3.90517 13.333 4.6401 13.8241 5.44894 14.1591C6.25778 14.4941 7.12468 14.6666 8.00016 14.6666C9.76827 14.6666 11.464 13.9642 12.7142 12.714C13.9645 11.4637 14.6668 9.76803 14.6668 7.99992C14.6668 7.12444 14.4944 6.25753 14.1594 5.4487C13.8243 4.63986 13.3333 3.90493 12.7142 3.28587C12.0952 2.66682 11.3602 2.17575 10.5514 1.84072C9.74255 1.50569 8.87564 1.33325 8.00016 1.33325Z"
                                    fill={ this.state.uppercase?'#10B981':'#B0BACD'}
                                  />
                                </svg>{" "}
                                <Typography className="newPassword_validation_check_text_style">
                                  At least one capital letter
                                </Typography>
                              </span>
                            </Box>
                            <Box>
                              <span className="newPassword_validation_check_span">
                                <svg 
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M6.66683 11.3333L3.3335 7.99992L4.2735 7.05325L6.66683 9.44659L11.7268 4.38659L12.6668 5.33325M8.00016 1.33325C7.12468 1.33325 6.25778 1.50569 5.44894 1.84072C4.6401 2.17575 3.90517 2.66682 3.28612 3.28587C2.03588 4.53612 1.3335 6.23181 1.3335 7.99992C1.3335 9.76803 2.03588 11.4637 3.28612 12.714C3.90517 13.333 4.6401 13.8241 5.44894 14.1591C6.25778 14.4941 7.12468 14.6666 8.00016 14.6666C9.76827 14.6666 11.464 13.9642 12.7142 12.714C13.9645 11.4637 14.6668 9.76803 14.6668 7.99992C14.6668 7.12444 14.4944 6.25753 14.1594 5.4487C13.8243 4.63986 13.3333 3.90493 12.7142 3.28587C12.0952 2.66682 11.3602 2.17575 10.5514 1.84072C9.74255 1.50569 8.87564 1.33325 8.00016 1.33325Z"
                                    fill={ this.state.lowercase?'#10B981':'#B0BACD'}
                                  />
                                </svg>{" "}
                                <Typography className="newPassword_validation_check_text_style">
                                  At least one lowercase letter
                                </Typography>
                              </span>
                            </Box>
                            <Box>
                              <span className="newPassword_validation_check_span">
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M6.66683 11.3333L3.3335 7.99992L4.2735 7.05325L6.66683 9.44659L11.7268 4.38659L12.6668 5.33325M8.00016 1.33325C7.12468 1.33325 6.25778 1.50569 5.44894 1.84072C4.6401 2.17575 3.90517 2.66682 3.28612 3.28587C2.03588 4.53612 1.3335 6.23181 1.3335 7.99992C1.3335 9.76803 2.03588 11.4637 3.28612 12.714C3.90517 13.333 4.6401 13.8241 5.44894 14.1591C6.25778 14.4941 7.12468 14.6666 8.00016 14.6666C9.76827 14.6666 11.464 13.9642 12.7142 12.714C13.9645 11.4637 14.6668 9.76803 14.6668 7.99992C14.6668 7.12444 14.4944 6.25753 14.1594 5.4487C13.8243 4.63986 13.3333 3.90493 12.7142 3.28587C12.0952 2.66682 11.3602 2.17575 10.5514 1.84072C9.74255 1.50569 8.87564 1.33325 8.00016 1.33325Z"
                                    fill={ this.state.number?'#10B981':'#B0BACD'}
                                  />
                                </svg>{" "}
                                <Typography className="newPassword_validation_check_text_style">
                                  At least one number
                                </Typography>
                              </span>
                            </Box>
                            <Box>
                              <span className="newPassword_validation_check_span">
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M6.66683 11.3333L3.3335 7.99992L4.2735 7.05325L6.66683 9.44659L11.7268 4.38659L12.6668 5.33325M8.00016 1.33325C7.12468 1.33325 6.25778 1.50569 5.44894 1.84072C4.6401 2.17575 3.90517 2.66682 3.28612 3.28587C2.03588 4.53612 1.3335 6.23181 1.3335 7.99992C1.3335 9.76803 2.03588 11.4637 3.28612 12.714C3.90517 13.333 4.6401 13.8241 5.44894 14.1591C6.25778 14.4941 7.12468 14.6666 8.00016 14.6666C9.76827 14.6666 11.464 13.9642 12.7142 12.714C13.9645 11.4637 14.6668 9.76803 14.6668 7.99992C14.6668 7.12444 14.4944 6.25753 14.1594 5.4487C13.8243 4.63986 13.3333 3.90493 12.7142 3.28587C12.0952 2.66682 11.3602 2.17575 10.5514 1.84072C9.74255 1.50569 8.87564 1.33325 8.00016 1.33325Z"
                                    fill={ this.state.minLength?'#10B981':'#B0BACD'}
                                  />
                                </svg>{" "}
                                <Typography className="newPassword_validation_check_text_style">
                                  Minimum character length is 8 characters
                                </Typography>
                              </span>
                            </Box>
                          </Box>
                        </Box>

                        <Box className="newPassword_outer_box">
                          <Button
                            type="submit"
                            className="newPassword_button"
                          >
                            <Typography className="newPassword_button_style">
                              Set New Password
                            </Typography>
                          </Button>

                          <Box className="newPassword_button_signup_outer_box">
                            <Typography className="newPassword_back_to_style_text">
                              Back to
                            </Typography>
                            <Button  
                            data-test-id="handleSigninClick"
                            onClick={()=>this.handleSigninClick()} className="newPassword_button_signup">
                              <Typography className="newPassword_button_signup_style_text">
                                Sign in
                              </Typography>
                            </Button>
                          </Box>
                        </Box>
                      </form>
                    )}
                  </Formik>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </ThemeProvider>
      </this.MainWrapper>
       // Customizable Area End
    );
  }
}
