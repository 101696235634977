export const userProfile = require("../assets/user-profile.png");
export const profileImage = require("../assets/profileImage.png");
export const dashboard = require("../assets/dashB.svg");
export const dashboardGray = require("../assets/dash_gray.svg");
export const logout = require("../assets/logout.svg");
export const logoutColored = require("../assets/logoutColored.svg");
export const transaction = require("../assets/transaction.svg");
export const transactionWhite = require("../assets/transaction_white.svg");
export const monthly = require("../assets/monthly.svg");
export const monthlyWhite = require("../assets/monthly_white.svg");
export const weekly = require("../assets/weekly.svg");
export const weeklyWhite = require("../assets/weekly_white.svg");
export const account = require("../assets/account.svg");
export const accountLetterWhite = require("../assets/letter_white.svg");
export const document = require("../assets/document.svg");
export const documentWhite = require("../assets/document_white.svg");
export const withdraw = require("../assets/withdraw.png");
export const deposit = require("../assets/deposit.png");
export const depositWhite = require("../assets/deposit_white.png");
export const withdrawWhite = require("../assets/withdraw_white.png");
export const threeLine = require("../assets/threeLine.svg");
export const leftIcon = require("../assets/leftIcon.svg");
export const defaultProfile = require("../assets/default.png")

