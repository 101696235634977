// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { Message } from "framework/src/Message";
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  feedbackNav?: any;
}

interface S {
  // Customizable Area Start
  reviewsList: any;
  comment: string;
  activeData:{
    first: number,
    second: number,
    [key: string]: any;
  };
  sendRequestPop:boolean;
  successPopup:boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class FeedbackController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
    ];
    // Customizable Area Start
    this.state = {
      comment: "",
      sendRequestPop:false,
      successPopup:true,
      reviewsList: [],
      activeData: {
        first: 0,
        second: 1,
        key:2
      },
    };
    // Customizable Area End
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  feedbackId: string = "";
  feedbackListId: string = "";

  async receive(from: string, message: Message) {
    this.handleReceivereviewList(from, message);
    this.handlePostFeedback(from ,message);
  }

  handleReceivereviewList = (from: string, message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.feedbackListId) {
      if(!apiResponse.errors) { 
        
        const sortedData = (apiResponse?.data || []).sort((a:any, b:any) => {
          const dateA:any = new Date(a.attributes.created_at);
          const dateB:any = new Date(b.attributes.created_at);
          return dateB - dateA;
        });
        const acceptedReviews = sortedData.filter((item:any) => item?.attributes?.status === 'accepted')

        this.setState({
          reviewsList: acceptedReviews
          });
      }
    }
  }

  handlePostFeedback = (from: string, message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.feedbackId) {
      if(!apiResponse.errors) {
        toast.success(apiResponse.meta.message)
        setTimeout(() => {
         this.setState({sendRequestPop: true})
        }, 500);
        this.setState({successPopup: !this.state.successPopup})
      } else {
        if(Object.keys(apiResponse.errors[0]).includes('comment')) {
          toast.error("Feedback can not be blank")
        }
        apiResponse.errors.forEach((item: string) => toast.error(item))
      }
      
    }
  }

  handleFeedbackPostApi = () => {
    const formResponseData = new FormData();
    formResponseData.append("feedback[comment]", this.state.comment);
    const header = {
      contentType: configJSON.contentTypeApiUpdateUser,
      token: localStorage.getItem("token"),
    };

    const feedbackData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    feedbackData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.feedbackId = feedbackData.messageId;

    feedbackData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.feedbackSubmitAPiEndPoint
    );
    feedbackData.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formResponseData
    );
    feedbackData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(feedbackData.id, feedbackData);
  };

  componentDidMount = async () => {
    this.handleListingFeedbackApi();
  };

  handleSendRequest =() => {
   this.setState({sendRequestPop:  !this.state.sendRequestPop})
  }

  sendRequestPopup = () => {
    this.setState({ sendRequestPop: false })
  }

  handleListingFeedbackApi = () => {
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": configJSON.contactUsApiContentType,
      token: token,
    };
    const feedbackDataa = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    feedbackDataa.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    this.feedbackListId = feedbackDataa.messageId;
    feedbackDataa.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.feedbackSubmitAPiEndPoint
    );
    feedbackDataa.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(feedbackDataa.id, feedbackDataa);
  };


  displayDate = (date: Date) => {
    const currentDate:any = new Date();
    const inputDate:any = new Date(date);
    currentDate.setHours(0, 0, 0, 0);
    inputDate.setHours(0, 0, 0, 0);
    const timeDiff = currentDate - inputDate;
    const dayDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    if (dayDiff === 0) {
      return inputDate.toLocaleDateString();
    } 
     else if (dayDiff === 1) {
      return `${dayDiff} day ago`;
    }else if (dayDiff > 1) {
      return `${dayDiff} days ago`;
    }
  };

  sharePopupFeedback = () => {
    this.setState({successPopup: !this.state.successPopup})
  }

  // Customizable Area End
}
