import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { userProfile, transaction, monthly, weekly, document, account, withdraw, deposit, transactionWhite, monthlyWhite, weeklyWhite, depositWhite, withdrawWhite, accountLetterWhite, documentWhite } from "./assets";
import { getStorageData, removeStorageData} from "../../../framework/src/Utilities";
import { getCurrentRoute } from "../../../components/src/utils.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  drawerContent?: boolean;
  profilePicture?:string;
  userFirstName?:string;
  userLastName?:string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  webDrawer: boolean;
  token: any;
  drawerItems: {}[];
  sideData: { iconActive: string, iconInactive: string, name: string, link: () => void, pathName: string }[];
  userName : string;
  userImage:string;
    logoutModal: boolean;
    currentRoute: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NavigationMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDataCallId: string = "";
  getUserProfileCallId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      webDrawer: false,
      token: "",
      drawerItems: [],
      userName:"",
      userImage:"",
            sideData: [
        {
          iconInactive: transaction,
          iconActive: transactionWhite,
          name: "Transaction Statements",
          link: () => this.navigateTo("TransactionStatement"),
          pathName: '/TransactionStatement',
        },
        {
          iconInactive: monthly,
          iconActive: monthlyWhite,
          name: "Monthly Report",
          link: () => {},
          pathName: '',
        },
        {
          iconInactive: weekly,
          iconActive: weeklyWhite,
          name: "Weekly Performances",
          link: () => {},
          pathName: '',
        },
        {
          iconInactive: deposit,
          iconActive: depositWhite,
          name: "Deposit Request",
          link: this.navigateToDeposit,
          pathName: '/DepositRequest'
        },
        {
          iconInactive: withdraw,
          iconActive: withdrawWhite,
          name: "Withdraw Request",
          link: this.navigateToWithdraw,
          pathName: '/WithdrawRequest'
        },
        {
          iconInactive: account,
          iconActive: accountLetterWhite,
          name: "Account Letter",
          link: () => {},
          pathName: '',
        },
        {
          iconInactive: document,
          iconActive: documentWhite,
          name: "My Documents",
          link: () => {},
          pathName: '',
        },
        
      ],
      logoutModal: false,
      currentRoute: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const sessionData = message.getData(getName(MessageEnum.SessionResponseData));
      if (sessionData) {
        if(sessionData.Message=="updateImage"){
          this.getUserDetails()
        }
      }
    }  
    const apiRequestCallIds = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJsonData = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallIds === this.getUserProfileCallId) {
      if (!responseJsonData.errors) {
        this.getResponseUserProfileData(responseJsonData);
        } else{
          this.parseApiErrorResponse(responseJsonData);
        }
      }

    // Customizable Area End
  }

  // Customizable Area Start
  toggleDrawer = () => {
    this.setState({ webDrawer: !this.state.webDrawer });
  };

  handleCondtion = (condition:boolean, truePart:object, falsePart:object) => {
    return condition ? truePart : falsePart
  }

  dashboardNavigation = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Dashboard");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  navigateToDeposit = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "DepositRequest");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  navigateToWithdraw = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "WithdrawRequest");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  onPressMenuItem = (menuItem: any) => {
    let path = menuItem.url;

    var tarea_regex = /^(http|https)/;
    if (tarea_regex.test(String(path).toLowerCase())) {
      // 
    } else {
      const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), path);
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    }
  };

  userProfileProps = {
    source: userProfile,
  };

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.getUserDetails()  
    this.setState({currentRoute: getCurrentRoute()})  
  }

  getToken = async() => {
   let token=await getStorageData("token");
   if(!token){
    const goTologin = new Message(getName(MessageEnum.NavigationMessage));
    goTologin.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    goTologin.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(goTologin);
  }

  };
  goToContractForm = () => {
    const goToContractForm = new Message(getName(MessageEnum.NavigationMessage));
    goToContractForm.addData(getName(MessageEnum.NavigationTargetMessage), "CustomForm");
    goToContractForm.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(goToContractForm);
  }
  
  navigateTo = (endpoint: string) => {
    const goToContractForm = new Message(getName(MessageEnum.NavigationMessage));
    goToContractForm.addData(getName(MessageEnum.NavigationTargetMessage), endpoint);
    goToContractForm.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(goToContractForm);
  }

  openLogoutModal = () => {
    this.setState({logoutModal: true})
  }

  closeLogoutModal = () => {
    this.setState({logoutModal: false})
  }
  
  logOutAndNavigate = async () => {
    this.closeLogoutModal()
    await removeStorageData('token');
    await removeStorageData('withdrawRequestId');
    await removeStorageData('depositRequestId');
    this.navigateTo("EmailAccountLoginBlock")
  }

  getActiveColor = (itemPath: string) => {
    return itemPath === this.state.currentRoute ? '#FFFFFF': '#94A3B8'
  }

  

  getUserDetails=async()=>{
    let token= await getStorageData("token");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserProfileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserDetailsApi
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      "token": token
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  getResponseUserProfileData=(response:any)=>{
    const userName = response.data.attributes.first_name+" "+response.data.attributes.last_name
    this.setState({userName:userName,userImage:response.data.attributes.profile_image?.url})
  }
  
  navigateToUserProfileBlock=()=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        'UserProfileBasicBlock'
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      raiseMessage.addData(getName(MessageEnum.SessionResponseData), { Message: "MyProfile"})
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(message);

  }
  
  // Customizable Area End
}
