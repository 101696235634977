import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  TextField,
  Button,
  styled,
  Grid,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";

// Customizable Area End

import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
 userSchema = () => {
  return Yup.object().shape({
    email: Yup.string()
      .email("Enter valid email id")
      .required("Please enter your email"),
  });
};


getErrorMessage = (touched: any, errors: any, value: any) => {
  return this.handleCondition(
    this.handleCondition(touched[value], errors[value], ""),
    <Typography
      style={{ 
        color: 'var(--Status-Red-600, #DC2626)',
        fontFamily: 'DIN Next LT Arabic Regular',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '18px', 
       }}
    >
      {errors[value]}
    </Typography>,
    ""
  );
};




MainWrapper = styled(Box)(({ theme: any }) => ({
 "& .box_left_column_3_main_box": {
    height: "160px",
    display: "flex",
    width: "423px",
    flexDirection: "column",
    alignItems: "center",
  },
  "& .grid_left": {
    backgroundColor: "#122A6A",
  },
  "& .grid_right": {
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.down("xs")]: {
      backgroundColor: "#122A6A",
    },
  },
  "& .main_box_left": {
    padding: 0,
    borderRadius: 0,
    justifyContent: "center",
    display: "flex",
    height: "100%",
    alignItems: "center",
  },
  "& .box_left": {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  "& .box_left_column_1": {
    height: "20%",
    display: "flex",
  },
  "& .box_left_column_1_logo": {
    margin: "50px 0px 0px 19px",
    height: "146px",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  "& .box_left_column_2_img": {
    display: "flex",
    height: "50%",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      widht: "22%",
    },
  },
  "& .box_left_column_3": {
    display: "flex",
    justifyContent: "center",
    height: "20%",
    alignItems: "center",
  },
  "& .box_left_column_2_img_tag": {
    padding: "66px",
    width: "50%",
    [theme.breakpoints.between("xs", "lg")]: {
      width: "60%",
      height: "auto",
      padding: "90px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "60px",
      width: "40%",
    },
  },
  "& .box_left_column_3_main_box_text_1": {
    height: "88px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "16px",
      height: "30px",
    },
  },
  "& .box_left_column_3_main_box_text_1_style": {
    fontFamily: "DIN Next LT Arabic Bold",
    lineHeight: "44px",
    fontSize: "31px",
    fontWeight: "700",
    textAlign: "center",
    letterSpacing: "-0.01em",
    color: "#F4F4EA",
    [theme.breakpoints.down(767)]: {
      fontSize: "25px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down(370)]: {
      fontSize: "15px",
    },
  },
  "& .box_left_column_3_main_box_text_2": {
    height: "56px",
    padding: "10px",
    marginTop: "16px",
  },
  "& .box_left_column_3_main_box_text_2_style": {
    fontFamily: "DIN Next LT Arabic Regular",
    fontWeight: "500",
    fontSize: "17px",
    lineHeight: "28px",
    color: "#DDE0E0",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontFamily: "DIN Next LT Arabic Regular",
      lineHeight: "28px",
      fontSize: "15px",
      fontWeight: "500",
      textAlign: "center",
      color: "#DDE0E0",
    },
    [theme.breakpoints.down(370)]: {
      fontFamily: "DIN Next LT Arabic Regular",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "28px",
      textAlign: "center",
      color: "#DDE0E0",
    },
  },
  /* ------------------------------------------*/
 
  "& .main_forgotPassword_outer_box": {
    margin: "260px 90px",
    flexDirection: 'column',
    display: 'flex',
    alignitems: 'center',
    [theme.breakpoints.down(920)]: {
      margin: "260px 65px",
    },
    [theme.breakpoints.down(820)]: {
      margin: "260px 55px",
    },
    [theme.breakpoints.down(750)]: {
      margin: "260px 45px",
    },
    [theme.breakpoints.down(670)]: {
      margin: "260px 35px",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0px 16px",
    },
  },
  "& .main_right_box": {
    borderRadius: "24px 24px 0px 0px",
    backgroundColor: "#FFFFFF",
    background: "var(--Basic-White, #FFF)",
  },
  "& .forgotPassword_style_text_1": {
    textAlign: "center",
    fontFamily: "DIN Next LT Arabic Bold",
    color: "var(--Neutrals-Cool-gray-900, #0F172A)",
    fontSize:"30px",
    fontStyle: 'normal',
    fontWeight: "700",
    letterSpacing: "-0.15px",
    lineHeight: "40px",
    [theme.breakpoints.down("xs")]: {
      paddingTop:'16px',
      textAlign: 'center',
      color: 'var(--Primary-Dark-900, #122967)',
      fontSize: '24px',
      fontStyle: 'normal',
      fontFamily: 'DIN Next LT Arabic Bold',
      fontWeight: '700',
      lineHeight: '32px',
      letterSpacing: '-0.12px',
    },

  },
  "& .forgotPassword_style_text_2": {
    textAlign: "center",
    fontFamily: "DIN Next LT Arabic Regular",
    color: "var(--Neutrals-Cool-gray-700, #334155)",
    fontSize: '16px',
    fontStyle: 'normal',
    lineHeight: "24px",
    fontWeight: 400,
    marginTop: "10px",
    [theme.breakpoints.down('xs')]:{
      marginTop:'16px'
    }
    
  },
  "& .forgotPassword_style_email_text": {
    fontStyle: 'normal',
    color: "var(--Neutrals-Cool-gray-700, #334155)",
    fontFamily: "DIN Next LT Arabic Bold",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
    marginTop: "40px",
    [theme.breakpoints.down('xs')]:{
      marginTop:'16px'
    }
  },
  "& .forgotPassword_outer_box": {
    display: "flex",
    flexDirection: "column",
    marginTop: "32px",
    alignItems: "center",
    gap: "16px",
    [theme.breakpoints.down('xs')]:{
      marginTop:'16px'
    }
  },
  "& .forgotPassword_button": {
    width: "100%",
    display: "flex",
    height: "44px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    padding: "10px 16px",
    background: "#1B4FE4",
    gap: "8px",
    textTransform: "none",
  },
  "& .forgotPassword_button_style": {
    fontFamily: "DIN Next LT Arabic Bold",
    color: theme.palette.common.white,
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: "24px",
    fontWeight: 700,
  },
  "& .forgotPassword_button_signup_outer_box": {
    display: "flex",
    alignItems: "baseline",
    height: "24px",
    gap: "0px",
    [theme.breakpoints.down('xs')]:{
      marginTop:'-16px'
    }
    
  
  },
  "& .forgotPassword_back_to_style_text": {
    fontFamily: "DIN Next LT Arabic Regular",
    color: "var(--Neutrals-Cool-gray-800, #1E293B)",
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    
  },
  "& .forgotPassword_button_signup": {
    display: "flex",
    justifyContent: "center",
    textTransform: "none",
    alignItems: "flex-start",
    gap: "8px",
  },
  "& .forgotPassword_button_signup_style_text": {
    color: "var(--Primary-Seconadry-500, #1B4FE4)",
    fontFamily: "DIN Next LT Arabic Bold",
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
  },
  "& .MuiOutlinedInput-input":{
    alignSelf: 'baseline',
    padding: '0px 0px !important',

}, "& .ForgotPassword_error_box": {
  background: "var(--Status-Red-100, #FEE2E2)",
  borderRadius: "4px",
  display: "flex",
  borderLeft: "4px solid var(--Status-Red-600, #DC2626)",
  padding: "12px 16px",
  alignItems: "center",
  gap: "8px",
  marginTop: "40px",
  backgroundColor: "#FEE2E2",
  color: "#DC2626",
},
"& .ForgotPassword_error_alert_box": {
  color: "#DC2626",
  backgroundColor: "#FEE2E2",
},
"& .ForgotPassword_error_alert_box_style": {
  color:'var(--Status-Red-600, #DC2626)',
  fontWeight: "400",
  fontFamily: "DIN Next LT Arabic Regular",
  fontSize: "14px",
  fontStyle: "normal",
  lineHeight: "22px",
  [theme.breakpoints.down(950)]: {
    fontFamily: "DIN Next LT Arabic Regular",
    fontWeight: "400",
    fontSize: "12px",
    fontStyle: "normal",
    lineHeight: "22px",
  },
  [theme.breakpoints.down(650)]: {
    fontFamily: "DIN Next LT Arabic Regular",
    fontStyle: "normal",
    fontSize: "10px",
    lineHeight: "22px",
    fontWeight: "400",
  },
},
}));
renderEmailValidation = (EmailValidationCondition:any)=>{
  return(
    EmailValidationCondition ? (
    <Box className="ForgotPassword_error_box">
    <Box className="ForgotPassword_error_alert_box">
      <Typography className="ForgotPassword_error_alert_box_style">
        {EmailValidationCondition}
      </Typography>
    </Box>
  </Box>
  ) : (
  ""
  )
)}
renderTokenExpireMessage = (TokenValidationCondition:any)=>{
  return(
    TokenValidationCondition ? (
  <Box className="ForgotPassword_error_box">
    <Box className="ForgotPassword_error_alert_box">
      <Typography className="ForgotPassword_error_alert_box_style">
        {TokenValidationCondition}
      </Typography>
    </Box>
  </Box>
) : (
  ""
))}
 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
      // Required for all blocks
      <this.MainWrapper>
      <ThemeProvider theme={theme}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} className="grid_left">
            <Box className="main_box_left">
              <Box className="box_left">
                <Box className="box_left_column_1">
                  <Box className="box_left_column_1_logo">
                    <img alt="Fundsmen" src={this.state.imgLogo} />
                  </Box>
                </Box>
                <Box className="box_left_column_2_img">
                  <img
                    className="box_left_column_2_img_tag"
                    alt="Fundsmen"
                    src={this.state.imgLeftLogo}
                  />
                </Box>
                <Box className="box_left_column_3">
                  <Box className="box_left_column_3_main_box">
                    <Box className="box_left_column_3_main_box_text_1">
                      <Typography className="box_left_column_3_main_box_text_1_style">
                        Invest your money for easy return
                      </Typography>
                    </Box>
                    <Box className="box_left_column_3_main_box_text_2">
                      <Typography className="box_left_column_3_main_box_text_2_style">
                        "Calculate Your Investment Returns in Real Time and
                        Start Growing Your Wealth Today"
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
       
          <Grid item xs={12} sm={6} className="grid_right">
          <Box className="main_right_box">
              <Box className="main_forgotPassword_outer_box">
                <Typography className="forgotPassword_style_text_1">
                  Forgot Password
                </Typography>
                <Typography className="forgotPassword_style_text_2">
                  No problem, we'll send a recovery link to your email.
                </Typography>
                {this.renderEmailValidation(this.state.EmailValidation)}
                {this.renderTokenExpireMessage(this.state.tokenExpireMessage)}
               
                 
                 
                <Formik
                  data-test-id="formik"
                  initialValues={{
                    email: this.state.email,
                  }}
                  validationSchema={this.userSchema}
                  onSubmit={(values : any) => {
                    this.handleRecoveryLinkClick(values);
                  }}
                >
                  {({
                   handleSubmit,
                   errors,
                   values,
                   handleBlur,
                   touched,
                   setFieldValue,
                  }) => (
                    <form
                      onSubmit={handleSubmit}
                      noValidate
                      autoComplete="off"
                    >
                <Typography className="forgotPassword_style_email_text">
                  Email
                </Typography>

                <TextField
                        data-test-id = "txtInputEmail" 
                        className="MuiOutlinedInput-input"
                        variant="outlined"
                        name="email"
                        placeholder={"Enter your email"}
                        fullWidth
                        onBlur={handleBlur}
                        value={values.email}
                        onChange={(e) =>
                          setFieldValue("email", e.target.value)
                        }
                        error={!!errors.email}
                        style={{
                          width: "100%",
                        }}
                        InputProps={{
                          style: {
                            width: "100%",
                            padding: "10px 8px",
                            height: "44px",
                            display: "flex",
                            position: "relative",
                            flexShrink: 0,
                            borderRadius: "8px",
                            marginTop: "6px",
                            alignItems: "center",
                            background: theme.palette.common.white,
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontFamily: "DIN Next LT Arabic Regular",
                            fontWeight: 400,
                            lineHeight: "24px",
                            color: 'var(--Neutrals-Cool-gray-800, #1E293B)',
                          },
                          
                        }}
                      />
                      <Box style={{marginTop:'5px'}}>   {this.getErrorMessage(touched, errors, "email")}</Box>

                <Box className="forgotPassword_outer_box">
                  <Button type="submit" data-test-id="handleRecoveryLinkClick" className="forgotPassword_button">
                    <Typography className="forgotPassword_button_style">
                      Send a recovery link
                    </Typography>
                  </Button>
                  
                  <Box className="forgotPassword_button_signup_outer_box">
                    <Typography className="forgotPassword_back_to_style_text">
                      Back to
                    </Typography>
                    <Button data-test-id="handleSignupClick" onClick={this.handleSignupClick} className="forgotPassword_button_signup">
                      <Typography className="forgotPassword_button_signup_style_text">
                        Sign up
                      </Typography>
                    </Button>
                  </Box>
                </Box>
                </form>
                  )}
                </Formik>
              </Box>
            </Box>
          
          </Grid>
        </Grid>
      </ThemeProvider>
    </this.MainWrapper>
       // Customizable Area End
    );
  }
}
