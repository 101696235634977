import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export type SortOption = 
  | 'Request ID (Ascending)'
  | 'Request ID (Descending)'
  | 'Request Date (Newest to Oldest)'
  | 'Request Date (Oldest to Newest)'
  | 'Transaction Amount (Highest to Lowest)'
  | 'Transaction Amount (Lowest to Highest)'
  | 'Transaction Type (Deposit)'
  | 'Transaction Type (Withdrawal)'
  | 'Status (Completed)'
  | 'Status (Pending)'
  | 'Status (Rejected)';

type SortBy = 'request_id' | 'request_date' | 'received_money' | 'status' | 'transaction_type' | '';
type Order = 'asc' | 'desc' | 'accepted' | 'rejected' | 'pending' | 'withdrawal' | 'deposit' | '';

const useSortStates = () => {
  const { t } = useTranslation();
  const [sortBy, setSortBy] = useState<SortBy>('');
  const [order, setOrder] = useState<Order>('');

  const handleSort = (option: SortOption) => {
    let newSortBy: SortBy = '';
    let newOrder: Order = '';
  
    switch (option) {
      case t('sortOptions.request_id_asc'):
        newSortBy = 'request_id';
        newOrder = 'asc';
        break;
      case t('sortOptions.request_id_desc'):
        newSortBy = 'request_id';
        newOrder = 'desc';
        break;
      case t('sortOptions.request_date_new_old'):
        newSortBy = 'request_date';
        newOrder = 'desc';
        break;
      case t('sortOptions.request_date_old_new'):
        newSortBy = 'request_date';
        newOrder = 'asc';
        break;
      case t('sortOptions.transaction_amount_high_low'):
        newSortBy = 'received_money';
        newOrder = 'desc';
        break;
      case t('sortOptions.transaction_amount_low_high'):
        newSortBy = 'received_money';
        newOrder = 'asc';
        break;
      case t('sortOptions.transaction_type_deposit'):
        newSortBy = 'transaction_type';
        newOrder = 'deposit';
        break;
      case t('sortOptions.transaction_type_withdrawal'):
        newSortBy = 'transaction_type';
        newOrder = 'withdrawal';
        break;
      case t('sortOptions.status_completed'):
        newSortBy = 'status';
        newOrder = 'accepted';
        break;
      case t('sortOptions.status_pending'):
        newSortBy = 'status';
        newOrder = 'rejected';
        break;
      case t('sortOptions.status_rejected'):
        newSortBy = 'status';
        newOrder = 'pending';
        break;
      default:
        newSortBy = 'request_id';
        newOrder = 'asc';
    }
  
    setSortBy(newSortBy);
    setOrder(newOrder);
  
    return { newSortBy, newOrder };
  };
  

  return { sortBy, order, handleSort };
};

export default useSortStates;